<template>
  <v-app class="styleObj">
    <!-- Header -->
    <Header />

    <v-container fill-height>
      <!-- Soltion options -->
      <div v-if="solutionChosen == 0">
        <!-- <v-row align="center" justify="center">
          <v-col>
            <div class="text-h5 text-left white--text font-weight-medium">
              Graphene has the following solution available.
              <br />
              <span class="body-2">
                If you are unclear of the option to be selected, please contact
                your Graphene Economics representative.
              </span>
            </div>
          </v-col>
        </v-row> -->
        <v-row align="center" justify="center">
          <v-col :cols="6">
            <v-card height="400">
              <v-toolbar dark :color="$vuetify.theme.themes[theme].primary">
                <v-toolbar-title>
                  XML converter solution
                </v-toolbar-title>
              </v-toolbar>
              <v-icon
                button
                class="pa-8"
                :size="96"
                :color="$vuetify.theme.themes[theme].secondary"
                @click="solutionChosen = 1"
                >mdi-download</v-icon
              >
              <v-card-text class="text-left pa-4">
                This option is a pure conversion from Excel to XML scheme
                format. Using the Graphene Economics template, the CbCR data
                will be converted from an Excel document into the preferred SARS
                XML scheme version 2.0 format. A XML scheme version 2.0 of your
                data will be available for download.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <!-- Window group -->
      <v-row align="center" justify="center" v-if="solutionChosen > 0">
        <v-col :cols="8">
          <v-window v-model="window">
            <!-- Upload Excel -->
            <v-window-item>
              <v-row justify="center">
                <v-col :cols="8">
                  <v-card class="elevation-24">
                    <!-- Title -->
                    <v-toolbar
                      dark
                      :color="$vuetify.theme.themes[theme].primary"
                      class="elevation-0"
                    >
                      <v-toolbar-title>Data extraction</v-toolbar-title>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                    <br />

                    <!-- Excel Spreadsheet -->
                    <v-row justify="start">
                      <v-col cols="10" align="start" offset="1">
                        <h3 class="heading1">Excel Spreadsheet</h3>
                      </v-col>
                    </v-row>
                    <v-row justify="start">
                      <v-col cols="10" align="start" offset="1">
                        <v-file-input
                          ref="inputFile"
                          :error-messages="errorInputs[0]"
                          label="File input"
                          append-outer-icon="info"
                          @change="filesChange($event)"
                          @click:append-outer="
                            (moreInfoDialog = !moreInfoDialog),
                              (moreInfoIndex = 0)
                          "
                        />
                      </v-col>
                    </v-row>

                    <!-- Error message -->
                    <v-flex xs10 offset-xs1>
                      <v-alert
                        v-if="errorMessage1"
                        :value="true"
                        color="error"
                        icon="warning"
                        outlined
                        >{{ errorMessage1 }}</v-alert
                      >
                    </v-flex>

                    <!-- Extract data from Excel Button -->
                    <v-row justify="start">
                      <v-col cols="10" align="start" offset="1">
                        <v-btn
                          v-if="!errorMessage1"
                          dark
                          :color="$vuetify.theme.themes[theme].secondary"
                          @click="extractDataFromExcel"
                        >
                          Extract
                        </v-btn>
                        <v-btn
                          v-if="errorMessage1"
                          dark
                          :color="$vuetify.theme.themes[theme].secondary"
                          @click="clearInputs"
                          >Retry</v-btn
                        >
                      </v-col>
                    </v-row>

                    <br />
                  </v-card>
                </v-col>
              </v-row>
            </v-window-item>

            <!-- Display extracted data -->
            <v-window-item>
              <v-row justify="center" v-if="dataHasBeenExtractedFromExcel">
                <v-col :cols="12">
                  <v-card
                    class="elevation-24"
                    :height="600"
                    flat
                    :color="$vuetify.theme.themes[theme].primary"
                  >
                    <!-- Title -->
                    <v-toolbar
                      dark
                      :color="$vuetify.theme.themes[theme].primary"
                      class="elevation-0"
                    >
                      <v-toolbar-title>Data extracted</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="
                          dataHasBeenExtractedFromExcel = false;
                          previousWindow();
                        "
                        >Go back</v-btn
                      >
                    </v-toolbar>
                    <v-tabs
                      v-model="tab"
                      fixed-tabs
                      dark
                      :background-color="$vuetify.theme.themes[theme].primary"
                      :slider-color="$vuetify.theme.themes[theme].secondary"
                    >
                      <v-tab class="text-capitalize">Details</v-tab>
                      <v-tab class="text-capitalize">Table 1</v-tab>
                      <v-tab class="text-capitalize">Table 2</v-tab>
                      <v-tab class="text-capitalize">Table 3</v-tab>
                    </v-tabs>

                    <!-- Details of reporting entity -->
                    <v-card flat v-if="tab == 0">
                      <br />
                      <v-row justify="start">
                        <v-col cols="10" align="start" offset="1">
                          <strong>MNE Group Name:</strong>
                          {{ extractedData.nameMNEGroup }}
                          <br />
                          <strong>Reporting Entity:</strong>
                          {{ extractedData.reportingEntity }}
                          <br />
                          <strong>Reporting Role:</strong>
                          {{ extractedData.reportingRole }}
                          <br />
                          <strong>Reporting Period start:</strong>
                          {{ extractedData.startDate }}
                          <br />
                          <strong>Reporting Period end:</strong>
                          {{ extractedData.endDate }}
                          <br />
                          <strong>Currency:</strong>
                          {{ extractedData.currencyCode }}
                        </v-col>
                      </v-row>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />

                      <br />

                      <!-- Continue -->
                      <v-row justify="start">
                        <v-col cols="10" align="start" offset="1">
                          <v-btn
                            dark
                            :color="$vuetify.theme.themes[theme].secondary"
                            @click="nextWindow"
                          >
                            Continue
                          </v-btn>
                        </v-col>
                      </v-row>

                      <br />
                      <br />
                      <br />
                    </v-card>

                    <!-- Table 1 data table -->
                    <v-card
                      flat
                      v-if="tab == 1"
                      :color="$vuetify.theme.themes[theme].primary"
                      class="pa-3"
                      :rounded="0"
                      height="500"
                    >
                      <v-row>
                        <v-col cols="2" class="pb-0">
                          <div class="subtitle-2 text-left white--text">
                            <span class="text-weight-medium">Currency:</span>
                            {{ this.extractedData.currencyCode }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row justify="start">
                        <v-col cols="12" align="start">
                          <v-data-table
                            :headers="headers.table1"
                            :items="extractedData.listOfReports"
                            :items-per-page="25"
                            :height="372"
                            dense
                            calculated-width
                            flat
                          >
                            <template v-slot:body="{ items }">
                              <tbody>
                                <tr
                                  v-for="(item, index) in items"
                                  :key="item.name"
                                >
                                  <td>{{ index + 1 }}</td>
                                  <td>
                                    <CountryFlag
                                      :country="item.countryCode"
                                      size="normal"
                                    />
                                  </td>

                                  <td>{{ item.countryCode }}</td>

                                  <td>
                                    {{
                                      extractedData.listOfEntities[index]
                                        .constituentEntity.registeredName
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      formatAsCurrency(item.revenue.unrelated)
                                    }}
                                  </td>
                                  <td>
                                    {{ formatAsCurrency(item.revenue.related) }}
                                  </td>
                                  <td>
                                    {{ formatAsCurrency(item.revenue.total) }}
                                  </td>
                                  <td>
                                    {{
                                      formatAsCurrency(
                                        item.summary.profitOrLossBeforeIncomeTax
                                      )
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      formatAsCurrency(
                                        item.summary.incomeTaxPaid
                                      )
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      formatAsCurrency(
                                        item.summary.incomeTaxAccrued
                                      )
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      formatAsCurrency(
                                        item.summary.statedCapital
                                      )
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      formatAsCurrency(
                                        item.summary.accumulatedEarnings
                                      )
                                    }}
                                  </td>
                                  <td>{{ item.summary.numberOfEmployees }}</td>
                                  <td>
                                    {{ formatAsCurrency(item.summary.assets) }}
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-card>

                    <!-- Table 2 data table -->
                    <v-card
                      flat
                      v-if="tab == 2"
                      :color="$vuetify.theme.themes[theme].primary"
                      class="pa-3 pt-0"
                      :rounded="0"
                      height="500"
                    >
                      <br />
                      <v-row justify="start">
                        <v-col cols="12" align="start">
                          <v-data-table
                            :headers="headers.table2"
                            :items="extractedData.listOfEntities"
                            :items-per-page="25"
                            height="392"
                            dense
                            calculated-width
                          >
                            <template v-slot:body="{ items }">
                              <tbody>
                                <tr
                                  v-for="(item, index) in items"
                                  :key="item.name"
                                >
                                  <td>{{ index + 1 }}</td>
                                  <td>
                                    <CountryFlag
                                      :country="item.countryCode"
                                      size="normal"
                                    />
                                  </td>
                                  <td>
                                    {{ item.countryCode }}
                                  </td>
                                  <td>
                                    {{ item.constituentEntity.registeredName }}
                                  </td>
                                  <td>
                                    {{ item.constituentEntity.tradingName }}
                                  </td>
                                  <td>
                                    {{
                                      item.constituentEntity
                                        .companyRegistrationNumber
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      item.constituentEntity.issuedByCountry1
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      item.constituentEntity.taxReferenceNumber
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      item.constituentEntity.issuedByCountry2
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      item.constituentEntity
                                        .incorporatedCountryCode
                                    }}
                                  </td>
                                  <td>
                                    {{ item.address.addressType }}
                                  </td>
                                  <td>
                                    {{ item.address.address }}
                                  </td>
                                  <td>
                                    {{
                                      item.businessActivities
                                        .mainBusinessActivities
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      item.businessActivities
                                        .otherBusinessActivity
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-card>

                    <!-- Table 3 data table -->
                    <v-card
                      flat
                      v-if="tab == 3"
                      :color="$vuetify.theme.themes[theme].primary"
                      class="pa-3 pt-0"
                      :rounded="0"
                      height="500"
                    >
                      <br />
                      <v-row justify="start">
                        <v-col cols="12" align="start" offset="0">
                          <v-data-table
                            :headers="headers.table3"
                            :items="extractedData.listOfAdditionalInfos"
                            :items-per-page="25"
                            height="392"
                            dense
                            calculated-width
                          >
                            <template v-slot:body="{ items }">
                              <tbody>
                                <tr
                                  v-for="(item, index) in items"
                                  :key="item.name"
                                >
                                  <td>{{ index + 1 }}</td>
                                  <td>
                                    {{ item.otherInfo }}
                                  </td>
                                  <td>
                                    {{ item.countryCodes }}
                                  </td>
                                  <td>
                                    {{ item.summaryRefCodes }}
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                </v-col>
              </v-row>
            </v-window-item>

            <!-- Contail details -->
            <v-window-item>
              <v-row justify="center">
                <v-col :cols="10">
                  <v-card class="elevation-6">
                    <!-- Title -->
                    <v-toolbar
                      dark
                      :color="$vuetify.theme.themes[theme].primary"
                      class="elevation-0"
                    >
                      <v-toolbar-title>Contact Details</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn @click="previousWindow()">Go back</v-btn>
                    </v-toolbar>

                    <v-row justify="start">
                      <v-col cols="10" align="start" offset="1">
                        <v-form>
                          <!-- Surname input field -->
                          <v-row>
                            <v-col>
                              <v-text-field
                                id="surname"
                                v-model="contactDetails.surname"
                                prepend-icon="person"
                                name="surname"
                                label="Surname"
                                type="text"
                                :error-messages="
                                  requiredErrorForContactDetailsInput[0]
                                "
                                append-outer-icon="info"
                                @click:append-outer="
                                  (moreInfoDialog = !moreInfoDialog),
                                    (moreInfoIndex = 5)
                                "
                              />
                            </v-col>
                          </v-row>

                          <!-- First name input field -->
                          <v-row>
                            <v-col xs2>
                              <v-text-field
                                id="firstName"
                                v-model="contactDetails.firstName"
                                prepend-icon="person"
                                name="firstName"
                                label="First Name"
                                type="text"
                                :error-messages="
                                  requiredErrorForContactDetailsInput[1]
                                "
                                append-outer-icon="info"
                                @click:append-outer="
                                  (moreInfoDialog = !moreInfoDialog),
                                    (moreInfoIndex = 6)
                                "
                              />
                            </v-col>
                          </v-row>

                          <!-- Business Tel. 1 input field -->
                          <v-row>
                            <v-col xs2>
                              <v-text-field
                                id="busTel1"
                                v-model="contactDetails.busTel1"
                                prepend-icon="phone"
                                name="busTel1"
                                label="Business Tel. 1"
                                type="text"
                                :error-messages="
                                  requiredErrorForContactDetailsInput[2]
                                "
                                append-outer-icon="info"
                                @click:append-outer="
                                  (moreInfoDialog = !moreInfoDialog),
                                    (moreInfoIndex = 7)
                                "
                              />
                            </v-col>
                          </v-row>

                          <!-- Business Tel. 2 input field -->
                          <v-row>
                            <v-col xs2>
                              <v-text-field
                                id="busTel2"
                                v-model="contactDetails.busTel2"
                                prepend-icon="phone"
                                name="busTel2"
                                label="Business Tel. 2"
                                type="text"
                                :error-messages="
                                  requiredErrorForContactDetailsInput[3]
                                "
                                append-outer-icon="info"
                                @click:append-outer="
                                  (moreInfoDialog = !moreInfoDialog),
                                    (moreInfoIndex = 8)
                                "
                              />
                            </v-col>
                          </v-row>

                          <!-- Cell no. input field -->
                          <v-row>
                            <v-col xs2>
                              <v-text-field
                                id="cellNO"
                                v-model="contactDetails.cellNo"
                                prepend-icon="phone"
                                name="cellNo"
                                label="Cellphone No."
                                type="text"
                                :error-messages="
                                  requiredErrorForContactDetailsInput[4]
                                "
                                append-outer-icon="info"
                                @click:append-outer="
                                  (moreInfoDialog = !moreInfoDialog),
                                    (moreInfoIndex = 9)
                                "
                              />
                            </v-col>
                          </v-row>

                          <!-- Email input field -->
                          <v-row>
                            <v-col xs2>
                              <v-text-field
                                id="email"
                                v-model="contactDetails.email"
                                prepend-icon="email"
                                name="email"
                                label="Email"
                                type="text"
                                :error-messages="
                                  requiredErrorForContactDetailsInput[5]
                                "
                                append-outer-icon="info"
                                @click:append-outer="
                                  (moreInfoDialog = !moreInfoDialog),
                                    (moreInfoIndex = 10)
                                "
                              />
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-col>
                    </v-row>
                    <!-- Continue -->
                    <v-row justify="start">
                      <v-col cols="10" align="start" offset="1">
                        <v-btn
                          dark
                          :color="$vuetify.theme.themes[theme].secondary"
                          @click="validateContactDetails()"
                        >
                          Continue
                        </v-btn>
                      </v-col>
                    </v-row>
                    <br />
                  </v-card>
                </v-col>
              </v-row>
            </v-window-item>

            <!-- Solutions confirmation of details -->
            <v-window-item>
              <v-row justify="center">
                <v-col :cols="8">
                  <!-- Solution 1 - only converter and provide download -->
                  <v-card class="elevation-24" v-if="solutionChosen == 1">
                    <!-- Title -->
                    <v-toolbar
                      dark
                      :color="$vuetify.theme.themes[theme].primary"
                      class="elevation-0"
                    >
                      <v-toolbar-title>Convert to XML</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn @click="previousWindow()">Go back</v-btn>
                    </v-toolbar>
                    <br />

                    <v-card-text>
                      All the required information has been capture. If you are
                      confident it is all correct, you may proceed with
                      converting it to an XML file.
                    </v-card-text>

                    <!-- Continue -->
                    <v-row justify="start">
                      <v-col cols="10" align="start" offset="1">
                        <v-btn
                          dark
                          :color="$vuetify.theme.themes[theme].secondary"
                          @click="runXMLConversion(false)"
                        >
                          Convert
                        </v-btn>
                      </v-col>
                    </v-row>

                    <br />
                  </v-card>
                </v-col>
              </v-row>
            </v-window-item>

            <!-- Run -->
            <v-window-item>
              <v-row justify="center">
                <v-col :cols="10">
                  <v-card class="elevation-6" :height="620">
                    <!-- Title -->
                    <v-toolbar
                      dark
                      :color="$vuetify.theme.themes[theme].primary"
                      class="elevation-0"
                    >
                      <v-toolbar-title>CbCR XML Converter</v-toolbar-title>
                    </v-toolbar>
                    <v-row justify="start">
                      <v-col cols="10" align="start" offset="1">
                        <br />
                        <h3 class="heading1">
                          Progress
                        </h3>
                      </v-col>
                    </v-row>

                    <!-- Console output -->
                    <v-row justify="start">
                      <v-col cols="10" align="start" offset="1" class="pa-0">
                        <v-textarea
                          height="265"
                          :readonly="true"
                          filled
                          name="console"
                          label
                          :value="consoleOutput"
                          :background-color="$vuetify.theme.themes[theme].info"
                          :color="$vuetify.theme.themes[theme].info"
                        ></v-textarea>
                      </v-col>
                    </v-row>

                    <v-row justify="start">
                      <v-col cols="10" align="start" offset="1" class="pa-0">
                        <!-- Error message -->
                        <v-row
                          justify="center"
                          v-if="errorMessageForWhenBotRunning"
                        >
                          <v-col cols="11">
                            <v-alert
                              :value="true"
                              color="error"
                              icon="warning"
                              outlined
                              >{{ errorMessage }}</v-alert
                            >
                            <!-- Years don't match -->
                            <v-card v-if="displayMoreInfoForErrorMessage[0]">
                              <v-card-text>
                                <p class="text-sm-left">
                                  <strong
                                    >Reporting period token is valid
                                    for:</strong
                                  >
                                  {{ yearError[0] }}
                                </p>
                                <p class="text-sm-left">
                                  <strong
                                    >Reporting period found in excel
                                    spreadsheet:</strong
                                  >
                                  {{ yearError[1] }}
                                </p>
                              </v-card-text>
                            </v-card>
                            <!-- Portfolio names found -->
                            <v-card v-if="displayMoreInfoForErrorMessage[1]">
                              <v-card-text>
                                <p class="text-sm-left">
                                  <strong
                                    >List of Portfolio Names found:</strong
                                  >
                                </p>
                                <ul>
                                  <li
                                    v-for="(item, i) in portfolioNamesError"
                                    :key="i"
                                  >
                                    <p class="text-sm-left">{{ item }}</p>
                                  </li>
                                </ul>
                                <p class="text-sm-left">
                                  <strong>Portfolio name given:</strong>
                                  {{ portfolioName }}
                                </p>
                              </v-card-text>
                            </v-card>
                            <!--Company numbers found -->
                            <v-card v-if="displayMoreInfoForErrorMessage[2]">
                              <v-card-text>
                                <p class="text-sm-left">
                                  <strong
                                    >List of Company Numbers found:</strong
                                  >
                                </p>
                                <ul>
                                  <li
                                    v-for="(item, i) in companyNumbersError"
                                    :key="i"
                                  >
                                    <p class="text-sm-left">{{ item }}</p>
                                  </li>
                                </ul>
                                <p class="text-sm-left">
                                  <strong>Company number given:</strong>
                                  {{ companyNumber }}
                                </p>
                              </v-card-text>
                            </v-card>
                            <br />

                            <!-- Retry button if error occurs -->
                            <v-btn
                              dark
                              :color="$vuetify.theme.themes[theme].secondary"
                              @click="retry"
                              v-if="!isOnlyRestartError"
                            >
                              RETRY</v-btn
                            >
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>

                    <v-row justify="start">
                      <v-col cols="10" align="start" offset="1">
                        <v-row justify="start">
                          <v-col cols="11" align="start">
                            <h3 class="text-justify primary--text text-h6 pb-0">
                              Output
                            </h3>
                          </v-col>
                          <v-col cols="1" align="end">
                            <v-btn
                              small
                              dark
                              fab
                              color="success"
                              :loading="loadingForButton"
                            >
                              <v-icon v-if="complete" color="white"
                                >mdi-check-bold</v-icon
                              >
                              <v-icon v-if="errorMessage" color="white"
                                >close</v-icon
                              >
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row justify="start">
                      <v-col cols="10" align="start" offset="1">
                        <!-- XML File -->
                        <v-row justify="start" v-if="complete">
                          <v-col cols="1" align="start" offset="0">
                            <icon name="xml" :width="24" :height="24"></icon>
                          </v-col>
                          <v-col cols="10" align="start" offset="0">
                            {{ xmlFileName }}
                          </v-col>
                          <v-col cols="1" align="end" offset="0">
                            <v-btn
                              icon
                              :disabled="!complete"
                              @click="download(2)"
                            >
                              <v-icon color="grey lighten-1"
                                >mdi-download</v-icon
                              >
                            </v-btn>
                          </v-col>
                        </v-row>

                        <!-- Report/Certificate -->
                        <v-row justify="start" v-if="complete">
                          <v-col cols="1" align="start" offset="0">
                            <icon name="pdf" :width="24" :height="24"></icon>
                          </v-col>
                          <v-col cols="10" align="start" offset="0">{{
                            outputFileName
                          }}</v-col>
                          <v-col cols="1" align="end" offset="0">
                            <v-btn
                              icon
                              :disabled="!complete"
                              @click="download(1)"
                            >
                              <v-icon color="grey lighten-1"
                                >mdi-download</v-icon
                              >
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <br />
                  </v-card>
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>
        </v-col>
      </v-row>

      <!-- Dots -->
      <v-row align="center" justify="center" v-if="solutionChosen">
        <v-col>
          <v-spacer></v-spacer>
          <v-item-group v-model="window" class="text-center" mandatory>
            <v-item v-for="n in length" :key="`btn-${n}`" v-slot="{ active }">
              <!-- <v-btn :input-value="active" icon @click="toggle"> -->
              <v-btn disabled :input-value="active" icon>
                <v-icon>mdi-record</v-icon>
              </v-btn>
            </v-item>
          </v-item-group>
          <v-spacer></v-spacer>
        </v-col>
      </v-row>

      <!-- MORE INFO DIALOG -->
      <v-dialog id="moreInfoDialog" v-model="moreInfoDialog" width="500">
        <v-card>
          <v-toolbar
            dark
            :color="$vuetify.theme.themes[theme].primary"
            class="elevation-0"
          >
            <v-toolbar-title class="font-weight-medium ">{{
              inputFieldNames[moreInfoIndex]
            }}</v-toolbar-title>
          </v-toolbar>
          <br />
          <v-card-text>{{ inputFieldDescriptions[moreInfoIndex] }}</v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text outlined @click="moreInfoDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Data Extracted Dialog -->
      <v-dialog
        id="dataExtractedDialog"
        v-model="dataExtractedDialog"
        width="250"
        persistent
      >
        <v-card :color="$vuetify.theme.themes[theme].primary">
          <v-card-title class="headline white--text" primary-title>
            Data successfully extracted!</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              outlined
              dark
              @click="
                dataExtractedDialog = false;
                nextWindow();
              "
              >Ok</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Are you sure you want to leave Dialog -->

      <!-- Error Dialog -->
      <v-dialog
        id="excelFileUploadError"
        v-model="excelFileUploadError"
        width="400"
        persistent
      >
        <v-card>
          <v-card-title class="headline red white--text" primary-title>
            Error</v-card-title
          >
          <v-card-text>
            Data could not be extracted from the Excel spreadsheet. Please
            contact Graphene Economics should this problem persist.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text outlined @click="excelFileUploadError = false"
              >Ok</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Download link -->
      <a id="download" style="display=hidden"></a>
    </v-container>
  </v-app>
</template>

<script>
import Header from "../../components/Header.vue";
import MainServices from "../../services/MainServices";
import DocumentController from "../../controllers/DocumentController.js";
import store from "../../store.js";

import io from "../../../node_modules/socket.io-client/dist/socket.io";

export default {
  components: {
    Header
  },
  data() {
    return {
      drawer: false,
      group: 0,
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnROYW1lIjoiQUJEIFB0eSBMZHQiLCJ5ZWFyIjoiMjAyMCIsImlhdCI6MTYwNzQ5NDY0M30.1JsKggzEMH-NZExYSInX2U6tmUh9LSFoZWh61FwNzU8",
      errorMessage: null,
      errorMessage0: null,
      errorMessage1: null,
      errorInputs: ["", "", "", "", "", "", "", "", "", "", ""],

      tokenVerified: false,
      window: 0,
      length: 5,
      moreInfoDialog: false,
      moreInfoIndex: 0,
      tokenVerifiedDialog: false,
      dataExtractedDialog: false,
      inputFieldNames: [
        "Excel Spreadsheet",
        "SARS e-filing - Username",
        "SARS e-filing - Password",
        "SARS e-filing - Portfolio Name",
        "SARS e-filing - Company Number",
        "Contact details - Surname",
        "Contact details - First name",
        "Contact details - Business Tel. 1",
        "Contact details - Business Tel. 2",
        "Contact details - Cellphone",
        "Contact details - Email"
      ],
      inputFieldDescriptions: [
        "Please upload a completed excel template file that was provided to you by Graphene Economics.",
        "Please enter the username of the SARS e-filing profile you wish to access.",
        "Please enter the password of the SARS e-filing profile you wish to access.",
        "Please enter the portfolio name of the SARS e-filing portfolio you wish to select.",
        "Please enter the company number of the taxpayer you wish to select under the given portfolio name.",
        "Please enter the surname of the person 'signing' the declaration.",
        "Please enter the first name of the person 'signing' the declaration.",
        "Please enter the first business telephone number of the person 'signing' the declaration.",
        "Please enter the second business telephone number of the person 'signing' the declaration.",
        "Please enter the cellphone number of the person 'signing' the declaration.",
        "Please enter the email address of the person 'signing' the declaration."
      ],
      loadingForButton: true,
      tab: 0,
      headers: {
        table1: [
          {
            text: "",
            align: "start",
            sortable: false,
            value: "index"
          },
          {
            text: "",
            align: "start",
            sortable: false,
            value: "flag"
          },
          {
            text: "Country Code",
            align: "start",
            sortable: false,
            value: "countryCode"
          },
          {
            text: "Registered Name",
            align: "start",
            sortable: false,
            value: "registeredName"
          },
          {
            text: "Revenue - Unrelated",
            align: "start",
            sortable: false,
            value: "revenueUnrelated"
          },
          {
            text: "Revenue - Related",
            align: "start",
            sortable: false,
            value: "revenueRelated"
          },
          {
            text: "Revenue - Total",
            align: "start",
            sortable: false,
            value: "revenueTotal"
          },
          {
            text: "Profit/Loss before Income tax",
            align: "start",
            sortable: false,
            value: "profitOrLossBeforeIncomeTax"
          },
          {
            text: "Income Tax Paid",
            align: "start",
            sortable: false,
            value: "incomeTaxPaid"
          },
          {
            text: "Income Tax Accrued",
            align: "start",
            sortable: false,
            value: "incomeTaxAccrued"
          },
          {
            text: "Stated Capital",
            align: "start",
            sortable: false,
            value: "statedCapital"
          },
          {
            text: "Accumulated Earnings",
            align: "start",
            sortable: false,
            value: "accumulatedEarnings"
          },

          {
            text: "Number of Employees",
            align: "start",
            sortable: false,
            value: "numberOfEmployees"
          },

          {
            text: "Tangible Assets",
            align: "start",
            sortable: false,
            value: "assets"
          }
        ],
        table2: [
          {
            text: "",
            align: "start",
            sortable: false,
            value: "index"
          },
          {
            text: "",
            align: "start",
            sortable: false,
            value: "flag"
          },
          {
            text: "Country Code",
            align: "start",
            sortable: false,
            value: "countryCode"
          },
          {
            text: "Registered Name",
            align: "start",
            sortable: false,
            value: "registeredName"
          },
          {
            text: "Trading Name",
            align: "start",
            sortable: false,
            value: "tradingName"
          },
          {
            text: "Company Registration Number",
            align: "start",
            sortable: false,
            value: "companyRegistrationNumber"
          },
          {
            text: "Issued by Country",
            align: "start",
            sortable: false,
            value: "issuedByCountry1"
          },
          {
            text: "Tax Reference Number",
            align: "start",
            sortable: false,
            value: "taxReferenceNumber"
          },
          {
            text: "Issued by Country",
            align: "start",
            sortable: false,
            value: "issuedByCountry2"
          },
          {
            text: "Incorporated Country Code",
            align: "start",
            sortable: false,
            value: "incorporatedCountryCode"
          },
          {
            text: "Address Type",
            align: "start",
            sortable: false,
            value: "addressType"
          },
          {
            text: "Address",
            align: "start",
            sortable: false,
            value: "address"
          },
          {
            text: "Main business activities",
            align: "start",
            sortable: false,
            value: "mainBusinessActivities"
          },
          {
            text: "Other business activity",
            align: "start",
            sortable: false,
            value: "otherBusinessActivity"
          }
        ],
        table3: [
          {
            text: "",
            align: "start",
            sortable: false,
            value: "index"
          },
          {
            text: "Description",
            align: "start",
            sortable: false,
            value: "description"
          },
          {
            text: "Tax Jurisdiction(s)",
            align: "start",
            sortable: false,
            value: "countryCodes"
          },
          {
            text: "Summary Ref Code(s)",
            align: "start",
            sortable: false,
            value: "summaryRefCodes"
          }
        ]
      },
      dataHasBeenExtractedFromExcel: false,

      // Contact details
      contactDetails: {
        surname: null,
        firstName: null,
        busTel1: null,
        busTel2: null,
        cellNo: null,
        email: null
      },
      requiredErrorForContactDetailsInput: [null, null, null, null, null, null],

      // SARS profile
      SARSProfile: {
        username: null,
        password: null,
        portfolioName: null,
        companyNumber: null
      },

      // Output
      complete: false,
      consoleOutput: null,
      consoleOutputArray: [],
      outputFileName: "...",
      xmlFileName: "...",
      excelFile: null,
      socket: null,
      yearError: [],
      portfolioNamesError: [],
      errorMessageForWhenBotRunning: false,
      tokenID: null,

      // Solution options
      // 0: none chosen
      // 1: solution 1 chosen
      // 2: solution 2 chosen
      solutionChosen: 0,

      excelFileUploadError: false
    };
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    isLoggedIn() {
      return store.state.isLoggedIn;
    },
    tokenInUse() {
      return store.state.tokenInUse;
    },
    descriptionOfTokenInUse() {
      return store.state.descriptionOfTokenInUse;
    }
  },
  methods: {
    formatAsCurrency(value) {
      // Create our number formatter.
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: this.extractedData.currencyCode
      });

      return formatter.format(value);
    },
    clearInputs() {
      this.errorMessage0 = "";
      this.errorMessage1 = "";
      this.token = "";
      document.querySelector("#tokenInput").select();
      this.excelFile = null;
      // this.$refs.inputFile.reset();
    },
    filesChange(event) {
      this.excelFile = event;
    },
    nextWindow() {
      this.window = this.window + 1;
    },
    previousWindow() {
      this.window = this.window - 1;
    },
    async extractDataFromExcel() {
      try {
        // console.log("Data extract");

        // Start overlay loader
        store.dispatch("toggleGlobalOverlay");

        if (!this.excelFile) {
          this.errorMessage1 = "No file found.";
          // Stop overlay loader
          store.dispatch("toggleGlobalOverlay");
          return;
        }

        // Get excel doc
        // console.log(this.excelFile);
        const arrayBuffer = await this.excelFile.arrayBuffer();
        const uint8View = new Uint8Array(arrayBuffer);
        const array = [...uint8View];

        // Call service
        let res = await MainServices.extractData({ excelFile: array });

        if (res.data.error) {
          // Display error message
          this.errorMessage1 = res.data.error;
        } else {
          this.dataExtractedDialog = true;
          this.dataHasBeenExtractedFromExcel = true;
          this.tab = 0;

          this.extractedData = { ...res.data };
        }

        // Stop overlay loader
        store.dispatch("toggleGlobalOverlay");

        // console.log(this.extractedData);
      } catch (error) {
        // console.log(error);
      }
    },
    validateContactDetails() {
      this.requiredErrorForContactDetailsInput = [
        null,
        null,
        null,
        null,
        null,
        null
      ];
      let errors = false;
      if (this.contactDetails.surname == null) {
        this.requiredErrorForContactDetailsInput[0] = "Required.";
        errors = true;
      }
      if (this.contactDetails.firstName == null) {
        this.requiredErrorForContactDetailsInput[1] = "Required.";
        errors = true;
      }
      if (this.contactDetails.busTel1 == null) {
        this.requiredErrorForContactDetailsInput[2] = "Required.";
        errors = true;
      }
      if (this.contactDetails.busTel2 == null) {
        this.requiredErrorForContactDetailsInput[3] = "Required.";
        errors = true;
      }
      if (this.contactDetails.cellNo == null) {
        this.requiredErrorForContactDetailsInput[4] = "Required.";
        errors = true;
      }
      if (this.contactDetails.email == null) {
        this.requiredErrorForContactDetailsInput[5] = "Required.";
        errors = true;
      }

      if (!errors) {
        this.nextWindow();
      }
    },
    async runXMLConversion(uploadToSARS) {
      try {
        // Set running conversion

        // Start overlay loader
        store.dispatch("toggleGlobalOverlay");

        this.running = true;
        this.consoleOutput = "";
        this.consoleOutputArray = [];

        ///////////////////////////////////////////
        // Initiliaze web socket with the server //

        // Dev
        // this.socket = io.connect("http://localhost:8085/");

        // Prod
        this.socket = io.connect(
          "https://graphenetechplatform.azurewebsites.net/"
        );

        this.socket.on("consoleMessage", data => {
          // Only add log statement if it is not a repeat
          let index = this.consoleOutputArray.indexOf(data.msg);
          if (index == -1) {
            if (
              data.msg == "Graphene Bot initialsied." ||
              data.msg == "Starting..."
            ) {
              // Start of console output
              this.consoleOutputArray = [];
              this.consoleOutput = "";
              this.progressNumbers = [0, 0, 0];
              this.consoleOutputArray.push(data.msg);
              this.consoleOutput += data.msg;
            } else {
              this.consoleOutputArray.push(data.msg);
              this.consoleOutput += "\n" + data.msg;
            }
          }
        });
        this.socket.on("response", async () => {
          // Websocket no longer needed, close it
          this.socket.close();

          // Set the file names
          this.outputFileName = "XML Converter Certificate";
          this.xmlFileName = "XML File";

          // Update the conditon variables
          this.complete = true;
          this.loadingForButton = false;
        });
        this.socket.on("errorMessage", data => {
          // Display the error message in the error alert
          this.errorMessage = "ERROR: " + data;
          this.loadingForButton = false;
        });
        this.socket.on("errorRestart", data => {
          // Display the error message in the error alert
          this.errorMessage = "ERROR: " + data;
          this.isOnlyRestartError = true;
          setTimeout(() => {
            this.errorMessage = "";
            this.isOnlyRestartError = false;
            this.progressNumbers = [0, 0, 0];
          }, 3000);
        });
        this.socket.on("errorStop", data => {
          // Display the error message in the error alert
          this.errorMessage = "ERROR: " + data;
          this.isOnlyRestartError = true;
          setTimeout(() => {
            this.errorMessage = "";
            this.isOnlyRestartError = false;
            this.progressNumbers = [0, 0, 0];
            this.running = false;
          }, 3000);
        });
        this.socket.on("errorList", data => {
          // if it is the year error, get the different years to display
          // console.log(data);
          if (data.years) {
            this.yearError = [...data.years]; // change to years (for consistency)
            this.displayMoreInfoForErrorMessage[0] = true;
          }

          // if it is the portfolio name not found error, get the list of portfolio names found
          if (data.portfolioNames) {
            this.portfolioNamesError = [...data.portfolioNames];
            this.displayMoreInfoForErrorMessage[1] = true;
          }
          // if it is the company number not found error, get the list of company numbers found
          if (data.companyNumbers) {
            this.companyNumbersError = [...data.companyNumbers];
            this.displayMoreInfoForErrorMessage[2] = true;
          }
        });

        // Get excel doc
        const arrayBuffer = await this.excelFile.arrayBuffer();
        const uint8View = new Uint8Array(arrayBuffer);
        const array = [...uint8View];

        // Dummy contacnt details
        // let dummyContactDetails = {
        //   surname: "surname",
        //   firstName: "firstName",
        //   busTel1: "1234567890",
        //   busTel2: "0987654321",
        //   cellNo: "1223334445",
        //   email: "dummay@email.com"
        // };
        // this.contactDetails = { ...dummyContactDetails };

        let payload = {
          excelFile: array,
          excelFileExtension: DocumentController.getFileExtension(
            this.excelFile.type
          ),
          contactDetails: this.contactDetails,
          uploadToSARS
        };

        // Run conversion
        await MainServices.runXMLConversion(payload);

        // Next window
        this.nextWindow();

        // Stop overlay loader
        store.dispatch("toggleGlobalOverlay");
      } catch (error) {
        // console.log(error);
      }
    },
    async download(type) {
      // Start overlay loader
      store.dispatch("toggleGlobalOverlay");
      await DocumentController.download({
        type,
        year: this.extractedData.year
      });
      document.getElementById("download").click();
      // Stop overlay loader
      store.dispatch("toggleGlobalOverlay");
    }
  }
};
</script>

<style lang="scss" scoped>
.styleObj {
  background-color: #273c4e;
  height: 100%;
}
</style>
