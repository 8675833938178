<template>
  <v-app>
    <!-- Header -->
    <Header />

    <!-- Analytics Console -->
    <AnalyticsConsole />
  </v-app>
</template>

<script>
import Header from "../../components/Header";
import AnalyticsConsole from "../../components/AnalyticsConsole";

export default {
  components: {
    Header,
    AnalyticsConsole
  }
};
</script>

<style lang="scss" scoped></style>
