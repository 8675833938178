<template>
  <v-app class="styleObj">
    <v-main>
      <!-- Header -->
      <Header />

      <v-container>
        <v-row v-if="window > 0">
          <v-col :cols="12">
            <br />
            <v-btn @click="handleWindows()">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
          <v-col v-if="window > 1">
            <h1 class="title-1 text-uppercase white--text font-weight-medium">
              {{ MNEClientProfileSelected.name }}
            </h1>
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="12">
            <!-- Window group -->
            <v-window v-model="window">
              <!-- 0. Welcome -->
              <v-window-item>
                <v-row justify="center">
                  <v-col :cols="12">
                    <h1 class="title-1 white--text font-weight-medium">
                      Welcome to the Graphene Economics Technology Platform's
                      Admin Control.
                    </h1>
                    <!-- Explaination of how to use admin -->

                    <v-row justify="center">
                      <v-col :cols="12">
                        <p class="white--text font-weight-medium">
                          A Multinational enterprise (MNE) Client Profile stores
                          everything related to a single client, such as the,
                        </p>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <p class="white--text font-weight-medium text-left">
                        Users
                      </p>
                    </v-row>
                    <v-row justify="center">
                      <p class="white--text font-weight-medium text-left">
                        Data & XML
                      </p>
                    </v-row>
                    <v-row justify="center">
                      <p class="white--text font-weight-medium text-left">
                        Analytics
                      </p>
                    </v-row>
                    <v-row justify="center">
                      <v-col :cols="12">
                        <p class="white--text font-weight-medium">
                          The Admin Control Panel is used to manage the
                          different attributes associated to induvidual client
                          profiles.
                        </p>
                      </v-col>
                    </v-row>

                    <br />
                    <h1 class="subtitle-1 white--text font-weight-medium">
                      To get started, click below to VIEW the existing MNE
                      Client Profiles.
                    </h1>
                    <v-icon dark x-large>mdi-arrow-down-bold-outline</v-icon>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col align="center">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        width="200"
                        :color="$vuetify.theme.themes[theme].primary"
                        :elevation="hover ? 16 : 4"
                        @click.stop="window = 1"
                      >
                        <icon
                          name="infographic-analytics"
                          :width="100"
                          :height="100"
                        ></icon>
                        <v-card-text
                          style="position: relative;"
                          class="title font-weight-medium mb-2 pt-0"
                        >
                          <p v-if="hover" style="color: #FE5000">
                            MNE Client Profiles
                          </p>
                          <p v-if="!hover" style="color: white">
                            MNE Client Profiles
                          </p>
                        </v-card-text>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-window-item>

              <!-- 1. MNE -->
              <v-window-item>
                <v-row justify="center">
                  <v-col :cols="8">
                    <v-card class="elevation-6">
                      <v-toolbar
                        dark
                        :color="$vuetify.theme.themes[theme].primary"
                        class="elevation-0"
                      >
                        <v-toolbar-title class="font-weight-medium"
                          >MNE Client Profiles</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                          fab
                          :color="$vuetify.theme.themes[theme].secondary"
                          small
                          @click="addMNEDialog = true"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-toolbar>

                      <div v-if="!MNEClientProfilesFetchedSuccessfully">
                        <br />
                        <br />
                        <v-progress-circular indeterminate size="64" />
                        <br />
                        <br />
                      </div>
                      <div v-if="MNEClientProfilesFetchedSuccessfully">
                        <v-data-table
                          :headers="MNEClientProfileHeaders"
                          :items="MNEClientProfiles"
                          sort-by="name"
                          class="elevation-1"
                        >
                          <template v-slot:item.action="{ item }">
                            <v-btn
                              dark
                              :outlined="true"
                              :color="$vuetify.theme.themes[theme].secondary"
                              @click.stop="clientProfileSelectedHandler(item)"
                              >View</v-btn
                            >
                          </template>
                        </v-data-table>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-window-item>

              <!-- 2. MNE Select action -->
              <v-window-item>
                <v-row justify="center" fill-height>
                  <v-col :cols="12">
                    <h2 class="subtitle-1 white--text font-weight-medium">
                      Please select an option
                    </h2>

                    <!-- Options to select -->
                    <v-container fill-height fluid>
                      <v-row no-gutters dense>
                        <v-col align="center" class="ml-4 mr-2 mb-8">
                          <v-hover v-slot="{ hover }">
                            <v-card
                              width="200"
                              :color="$vuetify.theme.themes[theme].primary"
                              :elevation="hover ? 16 : 4"
                              @click.stop="
                                window = 3;
                                getAllUsers();
                              "
                            >
                              <icon
                                name="infographic-xml-converter"
                                :width="100"
                                :height="100"
                              ></icon>
                              <v-card-text
                                style="position: relative;"
                                class="title font-weight-medium mb-2 pt-0"
                              >
                                <p v-if="hover" style="color: #FE5000">
                                  Users
                                </p>
                                <p v-if="!hover" style="color: white">
                                  Users
                                </p>
                              </v-card-text>
                            </v-card>
                          </v-hover>
                        </v-col>
                        <v-col align="center" class="ml-2 mr-4">
                          <v-hover v-slot="{ hover }">
                            <v-card
                              width="200"
                              :color="$vuetify.theme.themes[theme].primary"
                              :elevation="hover ? 16 : 4"
                              @click.stop="
                                window = 4;
                                getAllSourceData();
                              "
                            >
                              <icon
                                name="infographic-xml-converter"
                                :width="100"
                                :height="100"
                              ></icon>
                              <v-card-text
                                style="position: relative;"
                                class="title font-weight-medium mb-2 pt-0"
                              >
                                <p v-if="hover" style="color: #FE5000">
                                  Data & XML
                                </p>
                                <p v-if="!hover" style="color: white">
                                  Data & XML
                                </p>
                              </v-card-text>
                            </v-card>
                          </v-hover>
                        </v-col>
                        <v-col align="center" class="ml-2 mr-4">
                          <v-hover v-slot="{ hover }">
                            <v-card
                              width="200"
                              :color="$vuetify.theme.themes[theme].primary"
                              :elevation="hover ? 16 : 4"
                              @click.stop="
                                window = 5;
                                getAllAnalyticsConsoles();
                              "
                            >
                              <icon
                                name="infographic-analytics"
                                :width="100"
                                :height="100"
                              ></icon>
                              <v-card-text
                                style="position: relative;"
                                class="title font-weight-medium mb-2 pt-0"
                              >
                                <p v-if="hover" style="color: #FE5000">
                                  Analytics
                                </p>
                                <p v-if="!hover" style="color: white">
                                  Analytics
                                </p>
                              </v-card-text>
                            </v-card>
                          </v-hover>
                        </v-col>
                      </v-row>
                      <br />
                      <br />
                      <br />
                    </v-container>
                  </v-col>
                </v-row>
              </v-window-item>

              <!-- 3. User -->
              <v-window-item>
                <v-row justify="center">
                  <v-col :cols="8">
                    <v-card class="elevation-6">
                      <v-toolbar
                        dark
                        :color="$vuetify.theme.themes[theme].primary"
                        class="elevation-0"
                      >
                        <v-toolbar-title class="font-weight-medium"
                          >Users</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                          fab
                          :color="$vuetify.theme.themes[theme].secondary"
                          small
                          @click="addUserDialog = true"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <div v-if="!usersFetchedSuccessfully">
                        <br />
                        <br />
                        <v-progress-circular indeterminate size="64" />
                        <br />
                        <br />
                      </div>
                      <div v-if="usersFetchedSuccessfully">
                        <v-data-table
                          :headers="usersHeaders"
                          :items="users"
                          sort-by="name"
                          class="elevation-1"
                        >
                          <template v-slot:item.action="{}">
                            <v-btn
                              disabled
                              :outlined="true"
                              :color="$vuetify.theme.themes[theme].secondary"
                              @click.stop=""
                              >Edit</v-btn
                            >
                          </template>
                        </v-data-table>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-window-item>

              <!-- 4. Source Data -->
              <v-window-item>
                <v-row justify="center">
                  <v-col :cols="8">
                    <v-card class="elevation-6">
                      <v-toolbar
                        dark
                        :color="$vuetify.theme.themes[theme].primary"
                        class="elevation-0"
                      >
                        <v-toolbar-title class="font-weight-medium">
                          Source Data
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                          fab
                          :color="$vuetify.theme.themes[theme].secondary"
                          small
                          @click="addExcelFileDialog = true"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <div v-if="!sourceDataFetchedSuccessfully">
                        <br />
                        <br />
                        <v-progress-circular indeterminate size="64" />
                        <br />
                        <br />
                      </div>
                      <div v-if="sourceDataFetchedSuccessfully">
                        <v-data-table
                          :headers="sourceDataHeaders"
                          :items="sourceData"
                          sort-by="name"
                          class="elevation-1"
                        >
                          <template v-slot:item.name="{}">
                            CbC Return
                          </template>

                          <!-- Conversion used? check box -->
                          <template v-slot:item.xml="{ item }">
                            <v-simple-checkbox
                              v-model="item.xmlExists"
                              disabled
                            ></v-simple-checkbox>
                          </template>

                          <!-- View button -->
                          <template v-slot:item.actions="{ item }">
                            <v-btn
                              dark
                              :outlined="true"
                              :color="$vuetify.theme.themes[theme].secondary"
                              @click.stop="
                                window = 6;
                                extractDataFromExcel(item);
                                forceRerender2();
                              "
                              >View</v-btn
                            >
                          </template>
                        </v-data-table>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-window-item>

              <!-- 5. Analytics Console -->
              <v-window-item>
                <v-row justify="center">
                  <v-col :cols="8">
                    <v-card class="elevation-6">
                      <v-toolbar
                        dark
                        :color="$vuetify.theme.themes[theme].primary"
                        class="elevation-0"
                      >
                        <v-toolbar-title class="font-weight-medium">
                          Analytics Console
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                          fab
                          :color="$vuetify.theme.themes[theme].secondary"
                          small
                          @click="addAnalyticsConsoleDialogHandler"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <div v-if="!analyticsConsolesFetchedSuccessfully">
                        <br />
                        <br />
                        <v-progress-circular indeterminate size="64" />
                        <br />
                        <br />
                      </div>
                      <div v-if="analyticsConsolesFetchedSuccessfully">
                        <v-data-table
                          :headers="analyticsConsolesHeaders"
                          :items="analyticsConsoles"
                          sort-by="name"
                          class="elevation-1"
                        >
                          <!-- View button -->
                          <template v-slot:item.actions="{}">
                            <v-btn
                              dark
                              :outlined="true"
                              :color="$vuetify.theme.themes[theme].secondary"
                              @click.stop="
                                window = 7;
                                forceRerender1();
                              "
                              >View</v-btn
                            >
                          </template>
                        </v-data-table>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-window-item>

              <!-- 6. View Data/XML -->
              <v-window-item>
                <ExcelDataDisplay
                  :extractedData="excelDataExtracted"
                  :key="componentKey2"
                />
                <v-row justify="start">
                  <v-col cols="12" align="center" offset="0">
                    <v-btn
                      class="pa-2 ma-6"
                      @click.stop="downloadFileDialog = true"
                    >
                      Downloads
                      <!-- <icon name="xls" :size="10"></icon> -->
                    </v-btn>
                  </v-col>
                </v-row>
              </v-window-item>

              <!-- 7. View Analytics -->
              <v-window-item>
                <v-row justify="center">
                  <v-col align="center">
                    <v-card class="elevation-6 overflow-y-auto">
                      <AnalyticsConsole :key="componentKey1" />
                    </v-card>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>

            <!-- Add MNE Client Profile dialog -->
            <v-dialog
              id="addMNEDialog"
              v-model="addMNEDialog"
              width="500"
              persistent
            >
              <v-card>
                <v-toolbar
                  dark
                  :color="$vuetify.theme.themes[theme].primary"
                  class="elevation-0"
                >
                  <v-toolbar-title class="font-weight-medium">
                    Add a MNE Client profile
                  </v-toolbar-title>
                </v-toolbar>

                <v-row justify="start">
                  <v-col cols="10" align="start" offset="1">
                    <v-text-field
                      id="mneName"
                      v-model="mneName"
                      prepend-icon="mdi-card-account-details"
                      name="mneName"
                      label="MNE Client Name"
                      type="text"
                      :error-messages="
                        requiredErrorForNewMNEClientProfileInput[0]
                      "
                  /></v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="addMNEDialog = false">Cancel</v-btn>
                  <v-btn
                    dark
                    :color="$vuetify.theme.themes[theme].secondary"
                    @click="addMNEClientProfile"
                    >ADD</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Add new user dialog -->
            <v-dialog
              id="addUserDialog"
              v-model="addUserDialog"
              width="500"
              persistent
            >
              <v-card>
                <v-toolbar
                  dark
                  :color="$vuetify.theme.themes[theme].primary"
                  class="elevation-0"
                >
                  <v-toolbar-title class="font-weight-medium">
                    Add a new User
                  </v-toolbar-title>
                </v-toolbar>

                <v-row justify="start">
                  <v-col cols="10" align="start" offset="1">
                    <v-text-field
                      id="newUserName"
                      v-model="newUser.name"
                      prepend-icon="mdi-account"
                      name="newUserName"
                      label="Name"
                      type="text"
                      :error-messages="requiredErrorForNewUserInput[0]"
                  /></v-col>
                  <v-col cols="10" align="start" offset="1">
                    <v-text-field
                      id="newUserUsername"
                      v-model="newUser.username"
                      prepend-icon="mdi-account"
                      name="newUserUsername"
                      label="Username"
                      type="text"
                      :error-messages="requiredErrorForNewUserInput[1]"
                  /></v-col>
                  <v-col cols="10" align="start" offset="1">
                    <v-text-field
                      id="newUserUsername"
                      v-model="newUser.email"
                      prepend-icon="mdi-email"
                      name="newUserEmail"
                      label="Email"
                      type="email"
                      :error-messages="requiredErrorForNewUserInput[2]"
                  /></v-col>
                  <v-col cols="10" align="start" offset="1">
                    <v-text-field
                      id="newUserName"
                      v-model="newUser.password"
                      prepend-icon="mdi-lock"
                      name="newUserPassword"
                      label="Password"
                      type="password"
                      :error-messages="requiredErrorForNewUserInput[3]"
                  /></v-col>
                  <v-col cols="10" align="start" offset="1">
                    <v-text-field
                      id="newUserName"
                      v-model="newUser.confirmPassword"
                      prepend-icon="mdi-lock"
                      name="newUserConfirmPassword"
                      label="Confirm password"
                      type="password"
                      :error-messages="requiredErrorForNewUserInput[4]"
                  /></v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="addUserDialog = false">Cancel</v-btn>
                  <v-btn
                    dark
                    :color="$vuetify.theme.themes[theme].secondary"
                    @click="addNewUser()"
                    >ADD</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Add excel file dialog -->
            <v-dialog
              id="addExcelFileDialog"
              v-model="addExcelFileDialog"
              width="500"
              persistent
            >
              <v-card>
                <v-toolbar
                  dark
                  :color="$vuetify.theme.themes[theme].primary"
                  class="elevation-0"
                >
                  <v-toolbar-title class="font-weight-medium">
                    Upload an Excel Spreadsheet
                  </v-toolbar-title>
                </v-toolbar>

                <v-row justify="start">
                  <v-col cols="10" align="start" offset="1">
                    <v-file-input
                      ref="inputFile"
                      label="File input"
                      :error-messages="requiredErrorForNewExcelFileInput[0]"
                      @change="filesChange($event)"
                    />
                  </v-col>
                </v-row>
                <br />
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="
                      addExcelFileDialog = false;
                      fileInputError = '';
                    "
                    >Cancel</v-btn
                  >
                  <v-btn
                    dark
                    :color="$vuetify.theme.themes[theme].secondary"
                    @click="addSourceData()"
                    >ADD</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Add console dialog -->
            <v-dialog
              id="addAnalyticsConsoleDialog"
              v-model="addAnalyticsConsoleDialog"
              width="500"
              persistent
            >
              <v-card>
                <v-toolbar
                  dark
                  :color="$vuetify.theme.themes[theme].primary"
                  class="elevation-0"
                >
                  <v-toolbar-title class="font-weight-medium">
                    Add an analytics console
                  </v-toolbar-title>
                </v-toolbar>

                <!-- Form -->
                <v-row justify="start">
                  <v-col cols="10" align="start" offset="1">
                    <v-form>
                      <!-- Package type -->
                      <v-row>
                        <v-col>
                          <v-select
                            v-model="selectedPackageType"
                            :items="packageTypes"
                            menu-props="auto"
                            hide-details
                            label="Package"
                            prepend-icon="mdi-package"
                            single-line
                            :error-messages="
                              requiredErrorForNewAnalyticsConsoleInput[0]
                            "
                          ></v-select>
                        </v-col>
                      </v-row>

                      <!-- Years available-->
                      <v-row>
                        <v-col>
                          <v-select
                            v-model="selectedYears"
                            :items="availableYears"
                            prepend-icon="mdi-calendar"
                            label="Years"
                            multiple
                            chips
                            :error-messages="
                              requiredErrorForNewAnalyticsConsoleInput[1]
                            "
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-checkbox
                            v-model="useTemplateCheckbox"
                            label="Use PowerBi Report Analytics Template"
                          ></v-checkbox>
                          <v-select
                            v-if="!useTemplateCheckbox"
                            v-model="reportSelected"
                            :items="existingReportsNameOnly"
                            prepend-icon="mdi-report"
                            label="Select an existing report to clone from"
                            :error-messages="
                              requiredErrorForNewAnalyticsConsoleInput[2]
                            "
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>

                <br />
                <br />
                <br />
                <br />
                <br />
                <br />

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="addAnalyticsConsoleDialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    dark
                    :color="$vuetify.theme.themes[theme].secondary"
                    @click="addAnalyticsConsole"
                    >ADD</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Download files dialog -->
            <v-dialog
              id="downloadFileDialog"
              v-model="downloadFileDialog"
              width="500"
              persistent
            >
              <v-card>
                <v-toolbar
                  dark
                  :color="$vuetify.theme.themes[theme].primary"
                  class="elevation-0"
                >
                  <v-toolbar-title class="font-weight-medium">
                    Download files
                  </v-toolbar-title>
                </v-toolbar>
                <br />

                <!-- Excel Spreadsheet -->
                <v-row>
                  <v-col :cols="2">
                    <icon name="xls" :size="28"></icon>
                  </v-col>
                  <v-col :cols="8">
                    <p>Excel Spreadsheet</p>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col :cols="2">
                    <v-icon class="mr-2" @click.stop="download(0)"
                      >mdi-download</v-icon
                    >
                  </v-col>
                </v-row>

                <!-- XML File -->
                <v-row>
                  <v-col :cols="2">
                    <icon name="xml" :size="28"></icon>
                  </v-col>
                  <v-col :cols="8">
                    <p>XML File</p>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col :cols="2">
                    <v-icon
                      :disabled="xmlDownloadDisabled"
                      class="mr-2"
                      @click.stop="download(2)"
                      >mdi-download</v-icon
                    >
                  </v-col>
                </v-row>

                <!-- PDF Output -->
                <v-row>
                  <v-col :cols="2">
                    <icon name="pdf" :size="28"></icon>
                  </v-col>
                  <v-col :cols="8">
                    <p>XML Conversion Certificate</p>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col :cols="2">
                    <v-icon
                      :disabled="xmlDownloadDisabled"
                      class="mr-2"
                      @click.stop="download(1)"
                      >mdi-download</v-icon
                    >
                  </v-col>
                </v-row>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="downloadFileDialog = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>

        <!-- Download link -->
        <a id="download" style="display=hidden"></a>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import store from "../store.js";
import MainServices from "../services/MainServices";
import DocumentController from "../controllers/DocumentController.js";

import Header from "../components/Header.vue";
import AnalyticsConsole from "../components/AnalyticsConsole";
import ExcelDataDisplay from "../components/ExcelDataDisplay";

export default {
  components: {
    Header,
    AnalyticsConsole,
    ExcelDataDisplay
  },
  data() {
    return {
      window: 0,
      drawer: false,
      group: 0,

      // MNE Client Profiles
      MNEClientProfilesFetchedSuccessfully: true,
      MNEClientProfiles: [],
      MNEClientProfileSelected: null,
      MNEClientProfileHeaders: [
        {
          text: "MNE Client Name",
          align: "start",
          sortable: true,
          value: "name"
        },
        {
          text: "",
          value: "action",
          sortable: false,
          align: "right"
        }
      ],

      // Users
      userSelectedId: null,
      usersFetchedSuccessfully: false,
      usersHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name"
        },
        {
          text: "Username",
          align: "start",
          sortable: true,
          value: "username"
        },
        {
          text: "Email",
          align: "start",
          sortable: true,
          value: "email"
        },
        {
          text: "",
          align: "right",
          sortable: false,
          value: "action"
        }
      ],
      users: [],

      // Source Data
      sourceData: null,
      sourceDataFetchedSuccessfully: null,
      sourceDataHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Year",
          align: "start",
          sortable: true,
          value: "year"
        },
        {
          text: "XML file?",
          value: "xml",
          sortable: true,
          align: "center"
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          align: "right"
        }
      ],
      excelDataExtracted: {},
      downloadFileDialog: false,
      yearSelected: null,
      xmlDownloadDisabled: true,

      // Analytics Consoles
      analyticsConsoles: null,
      analyticsConsolesFetchedSuccessfully: false,
      analyticsConsolesHeaders: [
        {
          text: "Console",
          align: "start",
          sortable: true,
          value: "name"
        },
        {
          text: "Package",
          align: "start",
          sortable: true,
          value: "package" // Description of type
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          align: "right"
        }
      ],

      ///////////
      // Dialogs
      ///////////

      // MNE Client Profile
      addMNEDialog: false,
      mneName: null,
      requiredErrorForNewMNEClientProfileInput: [null],

      // User
      addUserDialog: false,
      newUser: {
        name: null,
        username: null,
        password: null,
        confirmPassword: null,
        email: null
      },
      requiredErrorForNewUserInput: [null, null, null, null, null],

      // Source data
      addExcelFileDialog: false,
      excelFile: null,
      requiredErrorForNewExcelFileInput: [null],

      // Analytics Console
      addAnalyticsConsoleDialog: false,
      packageTypes: ["Basic", "Advanced"],
      selectedPackageType: null,
      availableYears: [],
      selectedYears: null,
      existingReports: [],
      existingReportsNameOnly: [],
      useTemplateCheckbox: false,
      reportSelected: null,
      requiredErrorForNewAnalyticsConsoleInput: [null, null, null],

      // Re-rendering
      componentKey1: 0,
      componentKey2: 0
    };
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },

    // Add new console
    allYearsSelected() {
      if (this.CbCRReturnForUserFetchedSuccessfully) {
        return this.selectedYears.length === this.CbCRReturnForUser.length;
      } else return null;
    },
    someYearsSelected() {
      return this.selectedYears.length > 0 && !this.CbCRReturnForUser;
    },
    icon() {
      if (this.allYearsSelected) return "mdi-close-box";
      if (this.someYearsSelected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    screenSize() {
      return store.state.screenSize;
    }
  },
  watch: {
    addMNEDialog: function() {
      this.mneName = null;
      this.requiredErrorForNewMNEClientProfileInput = [null];
    },
    addUserDialog: function() {
      this.newUser = {
        name: null,
        username: null,
        password: null,
        confirmPassword: null,
        email: null
      };
      this.requiredErrorForNewUserInput = [null, null, null, null, null];
    },
    addAnalyticsConsoleDialog: function() {
      this.selectedPackageType = null;
      this.selectedYears = null;
      this.useTemplateCheckbox = false;
      this.reportSelected = null;
      this.requiredErrorForNewAnalyticsConsoleInput = [null, null, null];
    },
    addExcelFileDialog: function() {
      this.excelFile = null;
      this.requiredErrorForNewExcelFileInput = [null];
    }
  },

  async mounted() {
    await this.getAllMNEClientProfiles();
  },
  methods: {
    // MNE - get all
    async getAllMNEClientProfiles() {
      try {
        const { MNEClientProfiles } = (
          await MainServices.getAllMNEClientProfiles()
        ).data;
        this.MNEClientProfiles = MNEClientProfiles;
        this.MNEClientProfilesFetchedSuccessfully = true;
      } catch (error) {
        // console.log(error);
      }
    },

    // MNE - add
    async addMNEClientProfile() {
      // Validate inputs
      let errors = false;
      this.requiredErrorForNewMNEClientProfileInput[0] = null;
      if (this.mneName == null) {
        this.requiredErrorForNewMNEClientProfileInput[0] = "Required.";
        errors = true;
      }

      // Add new MNE
      if (!errors) {
        // Start overlay loader
        store.dispatch("toggleGlobalOverlay");
        this.addMNEDialog = false;

        let payload = {
          name: this.mneName
        };

        await MainServices.addMNEClientProfile(payload);

        // Stop overlay loader
        store.dispatch("toggleGlobalOverlay");
        this.getAllMNEClientProfiles();
      } else {
        // toggle the dialog so that the error messages are updated
        this.addMNEDialog = false;
        this.addMNEDialog = true;
      }
    },

    // MNE - select handler
    clientProfileSelectedHandler(item) {
      this.window = 2;
      this.MNEClientProfileSelected = item;
      store.dispatch("setMNEName", this.MNEClientProfileSelected.name);
    },

    // Users - get all
    async getAllUsers() {
      try {
        const { clientUsers } = (await MainServices.getAllUsers()).data;
        this.users = clientUsers;
        this.usersFetchedSuccessfully = true;
      } catch (error) {
        // console.log(error);
      }
    },

    // Users - add
    async addNewUser() {
      // Validate inputs
      this.requiredErrorForNewUserInput = [null, null, null, null, null];
      let errors = false;
      if (this.newUser.name == null) {
        this.requiredErrorForNewUserInput[0] = "Required.";
        errors = true;
      }
      if (this.newUser.username == null) {
        this.requiredErrorForNewUserInput[1] = "Required.";
        errors = true;
      }
      if (this.newUser.email == null) {
        this.requiredErrorForNewUserInput[2] = "Required.";
        errors = true;
      }
      if (this.newUser.password == null) {
        this.requiredErrorForNewUserInput[3] = "Required.";
        errors = true;
      }
      if (this.newUser.confirmPassword == null) {
        this.requiredErrorForNewUserInput[4] = "Required.";
        errors = true;
      }

      if (!errors) {
        // Check passwords match
        if (this.newUser.password !== this.newUser.confirmPassword) {
          this.errorMessageForNewUser = "Passwords do not match!";
          this.requiredErrorForNewUserInput[4] =
            "Does not match password above.";
          this.addUserDialog = false;
          this.addUserDialog = true;
          return;
        } else {
          // Add new user to database
          // Start overlay loader
          store.dispatch("toggleGlobalOverlay");
          this.addUserDialog = false;

          let payload = { ...this.newUser };
          payload.MNESelected = this.MNEClientProfileSelected;

          await MainServices.createUser(payload);
          this.getAllUsers();

          // Stop overlay loader
          store.dispatch("toggleGlobalOverlay");
        }
      } else {
        // toggle the dialog so that the error messages are updated
        this.addUserDialog = false;
        this.addUserDialog = true;
      }
    },

    // Source Data - get all
    async getAllSourceData() {
      const { sourceData } = (await MainServices.getAllSourceData()).data;

      this.sourceData = sourceData;
      this.sourceDataFetchedSuccessfully = true;
    },

    // Source Data - add
    async addSourceData() {
      try {
        // Validate inputs
        let errors = false;
        this.requiredErrorForNewExcelFileInput[0] = null;
        if (this.excelFile == null) {
          this.requiredErrorForNewExcelFileInput[0] = "Required.";
          errors = true;
        }

        // Add new MNE
        if (!errors) {
          // Start overlay loader
          store.dispatch("toggleGlobalOverlay");

          // Get excel doc
          const arrayBuffer = await this.excelFile.arrayBuffer();
          const uint8View = new Uint8Array(arrayBuffer);
          const array = [...uint8View];

          this.addExcelFileDialog = false;

          let payload = {
            mneId: this.MNEClientProfileSelected.id,
            excelFile: array,
            excelFileExtension: DocumentController.getFileExtension(
              this.excelFile.type
            )
          };

          await MainServices.addSourceData(payload);

          // Reload table of source data
          await this.getAllSourceData();

          // Stop overlay loader
          store.dispatch("toggleGlobalOverlay");
        } else {
          // toggle the dialog so that the error messages are updated
          this.addExcelFileDialog = false;
          this.addExcelFileDialog = true;
        }
      } catch (error) {
        this.fileInputError = error;
      }
    },

    // Load source data for displaying
    async extractDataFromExcel(item) {
      try {
        // Start overlay loader
        store.dispatch("toggleGlobalOverlay");

        // Set year selected
        this.yearSelected = item.year;

        this.xmlDownloadDisabled = !item.xmlExists;

        // Call service
        let res = await MainServices.extractData({
          mneId: this.MNEClientProfileSelected.id,
          year: this.yearSelected
        });
        this.excelDataExtracted = { ...res.data };

        // Stop overlay loader
        store.dispatch("toggleGlobalOverlay");
      } catch (error) {
        // console.log(error);
      }
    },

    // Analytics consoles - get all
    async getAllAnalyticsConsoles() {
      const { analyticsConsoles } = (
        await MainServices.getAllAnalyticsConsoles(
          this.MNEClientProfileSelected
        )
      ).data;

      this.analyticsConsoles = analyticsConsoles;
      this.analyticsConsolesFetchedSuccessfully = true;
    },

    // Analytics consoles - add
    async addAnalyticsConsoleDialogHandler() {
      // Start overlay loader
      store.dispatch("toggleGlobalOverlay");
      this.addAnalyticsConsoleDialog = true;

      // Get list of exisiting PowerBi reports
      this.existingReports = (
        await MainServices.getListOfExistingPowerBiReports()
      ).data.list;

      this.existingReportsNameOnly = this.existingReports.map(
        report => report.name
      );

      await this.getAllSourceData();
      this.availableYears = this.sourceData.map(data => data.year);

      // Stop overlay loader
      store.dispatch("toggleGlobalOverlay");
    },
    async addAnalyticsConsole() {
      // Validate inputs
      let errors = false;
      this.requiredErrorForNewAnalyticsConsoleInput = [null, null, null];
      if (this.selectedPackageType == null) {
        this.requiredErrorForNewAnalyticsConsoleInput[0] = "Required.";
        errors = true;
      }
      if (this.selectedYears == null) {
        this.requiredErrorForNewAnalyticsConsoleInput[1] = "Required.";
        errors = true;
      }
      if (this.useTemplateCheckbox == false && this.reportSelected == null) {
        this.requiredErrorForNewAnalyticsConsoleInput[2] = "Required.";
        errors = true;
      }

      // Add new Analytics Console
      if (!errors) {
        this.addAnalyticsConsoleDialog = false;

        // Start overlay loader
        store.dispatch("toggleGlobalOverlay");

        let type;
        if (this.selectedPackageType.toLowerCase() == "basic") {
          type = 0;
        } else if (this.selectedPackageType.toLowerCase() == "advanced") {
          type = 1;
        }

        // Get the reportId of the report from which to clone
        let indexOfTemplateReport = this.useTemplateCheckbox
          ? this.existingReportsNameOnly.indexOf("Analytics Template")
          : this.existingReportsNameOnly.indexOf(this.reportSelected);

        let reportIdOfReportToDuplicate = this.existingReports[
          indexOfTemplateReport
        ].id;

        let payload = {
          type,
          years: this.selectedYears,
          reportIdOfReportToDuplicate
        };
        await MainServices.createAnalyticsConsole(payload);
        this.getAllAnalyticsConsoles();

        // Stop overlay loader
        store.dispatch("toggleGlobalOverlay");
      } else {
        // toggle the dialog so that the error messages are updated
        this.addAnalyticsConsoleDialog = false;
        this.addAnalyticsConsoleDialog = true;
      }
    },

    // Helper functions
    async toggleYearsSelected() {
      this.$nextTick(() => {
        if (this.allYearsSelected) {
          this.selectedYears = null;
        } else {
          this.selectedYears = this.CbCRReturnForUser.slice();
        }
      });
    },
    async download(type) {
      // Start overlay loader
      store.dispatch("toggleGlobalOverlay");
      this.downloadFileDialog = false;

      await DocumentController.download({
        mneId: this.MNEClientProfileSelected.id,
        type,
        year: this.yearSelected
      });
      document.getElementById("download").click();
      // Stop overlay loader
      store.dispatch("toggleGlobalOverlay");
    },
    filesChange(event) {
      this.excelFile = event;
    },

    handleWindows() {
      if (this.window <= 2) {
        this.window -= 1;
      } else if (this.window <= 5) {
        this.window = 2;
      } else if (this.window == 6) {
        this.window = 4;
      } else if (this.window == 7) {
        this.window = 5;
      }
    },

    forceRerender1() {
      this.componentKey1 += 1;
    },

    forceRerender2() {
      this.componentKey2 += 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.styleObj {
  background-color: #273c4e;
  height: 100%;
}
</style>
