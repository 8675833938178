import Vue from "vue";
import Vuetify from "vuetify/lib";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import customTheme from "./utilities/theme";
import Icon from "./components/Icon.vue";
import CountryFlag from "vue-country-flag";
import Highcharts from "highcharts";
import Maps from "highcharts/modules/map";
import TileMaps from "highcharts/modules/tilemap";    // Hexgon tiles
import HighchartsVue from "highcharts-vue";

import MainServices from "./services/MainServices";


Vue.config.productionTip = false;

// Custom component that creates an icon using an SVG file
Vue.component("icon", Icon);

// Flags
Vue.component('CountryFlag', CountryFlag);

// Highcharts 
Maps(Highcharts);
TileMaps(Highcharts);
Vue.use(HighchartsVue);

const vuetifyOptions = { 
  theme: customTheme,
  icons: {
    iconfont: 'mdi',
  },
};
Vue.use(Vuetify);

// Route protection
router.beforeEach(async (to, from, next) => {
  let continueFlag = true;

  // Token check for dashboard
  if ((to.path == "/dashboard") && (store.state.tokenInUse == null)) {
    continueFlag = false;
    router.push("/");
  }

  // Token clearing
  if (from.path == "/dashboard") {
    store.dispatch("clearToken");
  }

  // Logout of admin
  if (from.path == "/admin") {
    store.dispatch("logout");
  }

  // Login validation
  // if ((to.path == "/admin") && (!store.state.isLoggedIn)) {
  //   continueFlag = false;
  //   router.push("/");
  // }

  // Access contorl
  if (to.path == "/solutions") {
    store.dispatch("setNavSelected", 0);
  }
  else if (to.path == "/solutions/cbc-uploads") {
    continueFlag = (await MainServices.checkAccessControlForTool({
      toolIndex: 0
    })).data;

    // Update the Vuex store if access is not allowed
    if (!continueFlag) {
      store.dispatch("toggleAccessControl");
      store.dispatch("setNavSelected", 1);
    }
  }
  else if (to.path == "/solutions/analytics") {
    continueFlag = (await MainServices.checkAccessControlForTool({
      toolIndex: 1
    })).data;

    // Update the Vuex store if access is not allowed
    if (!continueFlag) {
      store.dispatch("toggleAccessControl");
      store.dispatch("setNavSelected", 2);
    }
  }

  // Call next middleware
  if (continueFlag) next();
})

new Vue({
  vuetify: new Vuetify(vuetifyOptions),
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
