<template>
  <div>
    <v-container fill-height fluid>
      <v-row justify="space-around">
        <!-- Selection section -->
        <v-col :cols="3">
          <template>
            <v-card
              dark
              :height="screenSize.height * 0.865"
              :color="$vuetify.theme.themes[theme].primary"
              :elevation="24"
            >
              <!-- Categories -->
              <v-card-title v-if="!globalOverlay" class="pa-0 ma-0">
                <v-row align="center" justify="start">
                  <v-col align="start" class="ml-4">
                    {{ globalOverlay ? "" : "Categories" }}
                  </v-col>
                  <v-col cols="2">
                    <v-select style="visibility: hidden"> </v-select>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider v-if="!globalOverlay"></v-divider>

              <v-list
                :color="$vuetify.theme.themes[theme].primary"
                v-if="!globalOverlay"
                :max-height="screenSize.height * 0.725"
                class="overflow-y-auto"
              >
                <v-list-group
                  v-for="(category, indexCategory) in categories"
                  :key="indexCategory"
                  no-action
                  color="secondary"
                  :value="selectedCategory[indexCategory]"
                  @click="changeCategoryType(indexCategory, 0)"
                >
                  <template v-slot:activator>
                    <v-icon left>{{ category.icon }}</v-icon>
                    {{ category.name }}
                    <v-spacer></v-spacer>
                  </template>
                  <v-list-item-group
                    :value="activeSubCategoryIndex"
                    color="secondary"
                    mandatory
                  >
                    <v-list-item
                      v-for="(
                        subCategory, indexSubCategory
                      ) in category.subCategories"
                      :key="indexSubCategory"
                      @click="
                        changeCategoryType(indexCategory, indexSubCategory)
                      "
                    >
                      <v-list-item-content
                        class="text-left align-self-start ml-4"
                      >
                        {{ subCategory.name }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list-group>
              </v-list>
            </v-card>
          </template>
        </v-col>

        <!--  Display section -->
        <v-col :cols="9">
          <v-card
            dark
            :height="screenSize.height * 0.865"
            :color="$vuetify.theme.themes[theme].primary"
            :elevation="24"
          >
            <v-card-title v-if="!globalOverlay" class="pa-0 ma-0">
              <v-row align="center">
                <v-col
                  align="start"
                  class="ml-4"
                  cols="9"
                  style="margin: 10px 0"
                >
                  {{
                    categories[activeCategoryIndex] &&
                    categories[activeCategoryIndex].subCategories.length == 0
                      ? categories[activeCategoryIndex].name
                      : categories[activeCategoryIndex].subCategories[
                          activeSubCategoryIndex
                        ].name
                  }}
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="2">
                  <!-- <v-select
                    class="mr-4"
                    :items="years"
                    :value="years[activeYearIndex]"
                    @change="filterYear($event)"
                  >
                  </v-select> -->
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider v-if="!globalOverlay"></v-divider>

            <!-- Content -->
            <v-row no-gutters align="center" justify="center" fill-height>
              <v-col>
                <v-row no-gutters align="center" justify="center" fill-height>
                  <v-col>
                    <v-card
                      :cols="10"
                      light
                      flat
                      :color="$vuetify.theme.themes[theme].primary"
                    >
                      <!-- Overlay -->
                      <v-overlay :value="displaySectionLoading" absolute>
                        <v-progress-circular indeterminate size="32" />
                      </v-overlay>

                      <!-- Business overview -->
                      <div
                        id="businessOverviewCategory"
                        style="display: none"
                        class="businessOverview"
                      >
                        <v-row
                          no-gutters
                          align="center"
                          justify="center"
                          fill-height
                        >
                          <v-col :cols="12">
                            <highcharts
                              id="overviewMap"
                              :constructorType="'mapChart'"
                              class="overviewMap"
                              ref="chart"
                              style="padding: 16px"
                              :options="chartOptions"
                            ></highcharts>
                          </v-col>
                        </v-row>
                        <v-card class="ml-4 mr-4">
                          <v-row
                            no-gutters
                            align="center"
                            justify="center"
                            fill-height
                            style="font-size: 12px"
                          >
                            <v-col :cols="3">
                              <v-card style="margin: 1rem" flat>
                                <p
                                  class="text-left align-self-start ma-1"
                                  style="color: #003057"
                                >
                                  <span class="font-weight-bold"
                                    >Number of Tax Jurisdictions:</span
                                  >
                                </p>
                                <p
                                  class="text-left align-self-start ma-1"
                                  style="color: #003057"
                                >
                                  <span class="font-weight-bold"
                                    >Number of Entities:</span
                                  >
                                </p>
                                <p
                                  class="text-left align-self-start ma-1"
                                  style="color: #003057"
                                >
                                  <span class="font-weight-bold"
                                    >Number of Employees:</span
                                  >
                                </p>
                              </v-card>
                            </v-col>

                            <v-col :cols="2">
                              <v-card style="margin: 1rem" flat>
                                <p
                                  class="text-right align-self-start ma-1"
                                  style="color: #003057"
                                >
                                  <span>{{
                                    sourceData[activeYearIndex].businessOverview
                                      .noCountries
                                  }}</span>
                                </p>
                                <p
                                  class="text-right align-self-start ma-1"
                                  style="color: #003057"
                                >
                                  <span>{{
                                    sourceData[activeYearIndex].businessOverview
                                      .noEntities
                                  }}</span>
                                </p>
                                <p
                                  class="text-right align-self-start ma-1"
                                  style="color: #003057"
                                >
                                  <span>{{
                                    sourceData[activeYearIndex].businessOverview
                                      .noEmployees
                                  }}</span>
                                </p>
                              </v-card>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col :cols="3">
                              <v-card style="margin: 1rem" flat>
                                <p
                                  class="text-left align-self-start ma-1"
                                  style="color: #003057"
                                >
                                  <span class="font-weight-bold"
                                    >Group Revenue:</span
                                  >
                                </p>
                                <p
                                  class="text-left align-self-start ma-1"
                                  style="color: #003057"
                                >
                                  <span class="font-weight-bold"
                                    >Group Profit:</span
                                  >
                                </p>
                                <p
                                  class="text-left align-self-start ma-1"
                                  style="color: #003057"
                                >
                                  <span class="font-weight-bold"
                                    >Most significant activity:</span
                                  >
                                </p>
                              </v-card>
                            </v-col>
                            <v-col :cols="3">
                              <v-card style="margin: 1rem" flat>
                                <p
                                  class="text-right align-self-start ma-1"
                                  style="color: #003057"
                                >
                                  <span>{{
                                    formatNumber(
                                      sourceData[activeYearIndex]
                                        .businessOverview.totalRevenue
                                    )
                                  }}</span>
                                </p>
                                <p
                                  class="text-right align-self-start ma-1"
                                  style="color: #003057"
                                >
                                  <span>{{
                                    formatNumber(
                                      sourceData[activeYearIndex]
                                        .businessOverview.totalProfit
                                    )
                                  }}</span>
                                </p>
                                <p
                                  class="text-right align-self-start ma-1"
                                  style="color: #003057"
                                >
                                  <span>{{
                                    sourceData[activeYearIndex].businessOverview
                                      .mostSignificantActivity
                                  }}</span>
                                </p>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card>
                      </div>

                      <!-- PowerBi report container -->
                      <div id="powerBiCategory">
                        <section
                          v-bind:style="{
                            display: 'none',
                            height: displayFullHeightForPowerBiEmbeddedSection
                              ? screenSize.height * 0.76 + 'px'
                              : screenSize.height * 0.76 - 80 + 'px',
                            padding: '16px',
                          }"
                          id="reportContainer"
                        ></section>
                        <!-- class="container" -->
                      </div>

                      <div id="sourceDataCategory" style="display: none">
                        <!-- Table 1 data table -->
                        <v-card
                          flat
                          v-if="activeSubCategoryIndex == 0"
                          :color="$vuetify.theme.themes[theme].primary"
                          class="pa-3"
                        >
                          <v-row>
                            <v-col cols="2" class="pb-0">
                              <div class="subtitle-2 text-left white--text">
                                <span class="text-weight-medium"
                                  >Currency:</span
                                >
                                {{ this.currencyCode }}
                              </div>
                            </v-col>
                          </v-row>
                          <v-row justify="start">
                            <!-- align="start" -->
                            <v-col cols="12" v-if="sourcDataRetreived">
                              <v-data-table
                                :headers="headers.table1"
                                :items="
                                  sourceData[activeYearIndex].listOfReports
                                "
                                :items-per-page="-1"
                                :height="screenSize.height * 0.61 + 'px'"
                                dense
                                calculate-widths
                                :color="$vuetify.theme.themes[theme].primary"
                              >
                                <template v-slot:body="{ items }">
                                  <tbody>
                                    <tr
                                      v-for="(item, index) in items"
                                      :key="item.name"
                                    >
                                      <td>{{ index + 1 }}</td>
                                      <td>
                                        <CountryFlag
                                          :country="item.countryCode"
                                          size="normal"
                                        />
                                      </td>

                                      <td>{{ item.countryCode }}</td>

                                      <td>
                                        {{
                                          sourceData[activeYearIndex]
                                            .listOfEntities[index]
                                            .constituentEntity.registeredName
                                        }}
                                      </td>
                                      <td>
                                        {{ item.revenue.unrelated }}
                                      </td>
                                      <td>
                                        {{ item.revenue.related }}
                                      </td>
                                      <td>
                                        {{ item.revenue.total }}
                                      </td>
                                      <td>
                                        {{
                                          item.summary
                                            .profitOrLossBeforeIncomeTax
                                        }}
                                      </td>
                                      <td>
                                        {{ item.summary.incomeTaxPaid }}
                                      </td>
                                      <td>
                                        {{ item.summary.incomeTaxAccrued }}
                                      </td>
                                      <td>
                                        {{ item.summary.statedCapital }}
                                      </td>
                                      <td>
                                        {{ item.summary.accumulatedEarnings }}
                                      </td>
                                      <td>
                                        {{ item.summary.numberOfEmployees }}
                                      </td>
                                      <td>
                                        {{ item.summary.assets }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-data-table>
                            </v-col>
                          </v-row>
                        </v-card>

                        <!-- Table 2 data table -->
                        <v-card
                          flat
                          v-if="activeSubCategoryIndex == 1"
                          :color="$vuetify.theme.themes[theme].primary"
                          class="pa-3"
                        >
                          <v-row justify="start">
                            <!-- align="start" -->
                            <v-col
                              cols="12"
                              offset="0"
                              v-if="sourcDataRetreived"
                            >
                              <v-data-table
                                :headers="headers.table2"
                                :items="
                                  sourceData[activeYearIndex].listOfEntities
                                "
                                :items-per-page="25"
                                :height="screenSize.height * 0.65 + 'px'"
                                dense
                                calculate-widths
                              >
                                <template v-slot:body="{ items }">
                                  <tbody>
                                    <tr
                                      v-for="(item, index) in items"
                                      :key="item.name"
                                    >
                                      <td>{{ index + 1 }}</td>
                                      <td>
                                        <CountryFlag
                                          :country="item.countryCode"
                                          size="normal"
                                        />
                                      </td>
                                      <td>
                                        {{ item.countryCode }}
                                      </td>
                                      <td>
                                        {{
                                          item.constituentEntity.registeredName
                                        }}
                                      </td>
                                      <td>
                                        {{ item.constituentEntity.tradingName }}
                                      </td>
                                      <td>
                                        {{
                                          item.constituentEntity
                                            .companyRegistrationNumber
                                        }}
                                      </td>
                                      <td>
                                        {{
                                          item.constituentEntity
                                            .issuedByCountry1
                                        }}
                                      </td>
                                      <td>
                                        {{
                                          item.constituentEntity
                                            .taxReferenceNumber
                                        }}
                                      </td>
                                      <td>
                                        {{
                                          item.constituentEntity
                                            .issuedByCountry2
                                        }}
                                      </td>
                                      <td>
                                        {{
                                          item.constituentEntity
                                            .incorporatedCountryCode
                                        }}
                                      </td>
                                      <td>
                                        {{ item.address.addressType }}
                                      </td>
                                      <td>
                                        {{ item.address.address }}
                                      </td>
                                      <td>
                                        {{
                                          item.businessActivities
                                            .mainBusinessActivities
                                        }}
                                      </td>
                                      <td>
                                        {{
                                          item.businessActivities
                                            .otherBusinessActivity
                                        }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-data-table>
                            </v-col>
                          </v-row>
                        </v-card>

                        <!-- Table 3 data table -->
                        <v-card
                          flat
                          v-if="activeSubCategoryIndex == 2"
                          :color="$vuetify.theme.themes[theme].primary"
                          class="pa-3"
                        >
                          <v-row justify="start">
                            <!-- align="start" -->
                            <v-col
                              cols="12"
                              offset="0"
                              v-if="sourcDataRetreived"
                              dense
                              calculate-widths
                            >
                              <v-data-table
                                :headers="headers.table3"
                                :items="
                                  sourceData[activeYearIndex]
                                    .listOfAdditionalInfos
                                "
                                :items-per-page="25"
                                :height="screenSize.height * 0.65 + 'px'"
                              >
                                <template v-slot:body="{ items }">
                                  <tbody>
                                    <tr v-for="item in items" :key="item.name">
                                      <td>
                                        {{ item.otherInfo }}
                                      </td>
                                      <td>
                                        {{ item.countryCodes }}
                                      </td>
                                      <td>
                                        {{ item.summaryRefCodes }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-data-table>
                            </v-col>
                          </v-row>
                        </v-card>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
                <!-- Bottom section -->
                <v-row
                  align="center"
                  justify="center"
                  v-if="!globalOverlay && showSubSubCategoriesNavigation"
                >
                  <v-col :cols="12" class="pt-0">
                    <v-card
                      flat
                      id="subSubCategoryNavigation"
                      style="display: block"
                      :color="$vuetify.theme.themes[theme].primary"
                    >
                      <!-- class="ma-2" -->
                      <v-card-actions class="justify-space-between">
                        <!-- Left arrow -->
                        <v-hover v-slot="{ hover }">
                          <v-btn
                            icon
                            @click.stop="changeSubSubCategory(0)"
                            :color="hover ? '#FE5000' : 'white'"
                            :disabled="displaySectionLoading"
                          >
                            <v-icon x-large> mdi-chevron-left </v-icon>
                          </v-btn>
                        </v-hover>
                        <v-item-group
                          v-model="activeSubSubCategoryIndex"
                          class="text-center"
                          mandatory
                        >
                          <v-item
                            v-for="(subsubcategory, i) in categories[
                              activeCategoryIndex
                            ].subCategories[activeSubCategoryIndex]
                              .subSubCategories.length"
                            :key="`btn_${i}`"
                            v-slot="{ active, toggle }"
                          >
                            <v-btn
                              :input-value="active"
                              icon
                              @click.stop="toggle(), loadPowerBiPage()"
                              :disabled="displaySectionLoading"
                            >
                              <v-icon
                                :color="active ? '#FE5000' : 'white'"
                                small
                                >mdi-record</v-icon
                              >
                            </v-btn>
                          </v-item>
                        </v-item-group>
                        <!-- Right arrow -->
                        <v-hover v-slot="{ hover }">
                          <v-btn
                            icon
                            @click.stop="changeSubSubCategory(1)"
                            :color="hover ? '#FE5000' : 'white'"
                            :disabled="displaySectionLoading"
                          >
                            <v-icon x-large> mdi-chevron-right </v-icon>
                          </v-btn>
                        </v-hover>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// Local files
import store from "../store.js";
import MainServices from "../services/MainServices";

// Libraries
import * as pbi from "powerbi-client";
import worldMap from "@highcharts/map-collection/custom/world.geo.json";

// Models
import Category from "../models/category";
import SubCategory from "../models/subCategory";
import SubSubCategory from "../models/subSubCategory";

export default {
  data() {
    return {
      displayOverlay: true,
      report: null,
      powerbi: null,
      embedConfiguration: null,
      embedContainer: null,
      displayTypes: [],
      activeTypeIndex: 0,
      activePageIndex: 0,
      activeVisualIndex: 0,
      hasPowerBiLoaded: false,

      consolidatedDataTableHeaders: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "flag",
        },
        {
          text: "Tax Jurisdiction",
          align: "start",
          sortable: false,
          value: "taxJurisdiction",
        },
        {
          text: "Country Code",
          align: "start",
          sortable: false,
          value: "countryCode",
        },
        {
          text: "Revenue - Unrelated",
          align: "start",
          sortable: false,
          value: "revenueUnrelated",
        },
        {
          text: "Revenue - Related",
          align: "start",
          sortable: false,
          value: "revenueRelated",
        },
        {
          text: "Revenue - Total",
          align: "start",
          sortable: false,
          value: "revenueTotal",
        },

        {
          text: "Profit/Loss before Income tax",
          align: "start",
          sortable: false,
          value: "profitOrLossBeforeIncomeTax",
        },
        {
          text: "Income Tax Paid",
          align: "start",
          sortable: false,
          value: "incomeTaxPaid",
        },
        {
          text: "Income Tax Accrued",
          align: "start",
          sortable: false,
          value: "incomeTaxAccrued",
        },
        {
          text: "Stated Capital",
          align: "start",
          sortable: false,
          value: "statedCapital",
        },
        {
          text: "Accumulated Earnings",
          align: "start",
          sortable: false,
          value: "accumulatedEarnings",
        },

        {
          text: "Number of Employees",
          align: "start",
          sortable: false,
          value: "numberOfEmployees",
        },

        {
          text: "Tangible Assets",
          align: "start",
          sortable: false,
          value: "assets",
        },
      ],
      consolidatedData: [],
      yearsFoundInConsolidatedTable1Data: [2018, 2019, 2020],
      selectedYear: [0],
      mapTypes: [
        {
          name: "Input maps",
          maps: [
            {
              name: "Employees",
              data: {},
            },
            {
              name: "Assets",
              data: {},
            },
            {
              name: "Stated Capital",
              data: {},
            },
            {
              name: "Aggregate",
              data: {},
            },
          ],
        },
        {
          name: "Output maps",
          maps: [
            {
              name: "Revenue",
              data: {},
            },
            {
              name: "Profibility",
              data: {},
            },
            {
              name: "Tax",
              data: {},
            },
            {
              name: "Aggregate",
              data: {},
            },
          ],
        },
      ],
      selectedMap: [true],
      activeMapTypeIndex: 0,
      activeMapIndex: 0,

      chartOptionBeingUsed: null,
      highchart: null,

      // New design
      displayFullHeightForPowerBiEmbeddedSection: true,

      sourcDataRetreived: false,
      sourceData: [{ businessOverview: {} }],
      currencyCode: "ZAR",

      categories: [],
      selectedCategory: [true],
      selectedSubCategory: [0],
      activeCategoryIndex: 0,
      activeSubCategoryIndex: 0,
      activeSubSubCategoryIndex: 0,

      showSubSubCategoriesNavigation: false,

      years: [],
      activeYearIndex: 0,

      reportContainer: null,
      reportConfiguration: null,
      displaySectionLoading: false,
      displaySectionOverlay: false,

      // Business overview
      businessOverview: {
        countries: 15,
        entities: 32,
        employees: 4000,
        revenue: 1000000,
        profit: 200000,
        mostSignificantActivity: "Business",
      },

      tab: 4,

      // Source data
      headers: {
        table1: [
          {
            text: "",
            align: "start",
            sortable: false,
            value: "index",
          },
          {
            text: "",
            align: "start",
            sortable: false,
            value: "flag",
          },
          {
            text: "Country Code",
            align: "start",
            sortable: false,
            value: "countryCode",
          },
          {
            text: "Registered Name",
            align: "start",
            sortable: false,
            value: "registeredName",
          },
          {
            text: "Revenue - Unrelated",
            align: "start",
            sortable: false,
            value: "revenueUnrelated",
          },
          {
            text: "Revenue - Related",
            align: "start",
            sortable: false,
            value: "revenueRelated",
          },
          {
            text: "Revenue - Total",
            align: "start",
            sortable: false,
            value: "revenueTotal",
          },
          {
            text: "Profit/Loss before Income tax",
            align: "start",
            sortable: false,
            value: "profitOrLossBeforeIncomeTax",
          },
          {
            text: "Income Tax Paid",
            align: "start",
            sortable: false,
            value: "incomeTaxPaid",
          },
          {
            text: "Income Tax Accrued",
            align: "start",
            sortable: false,
            value: "incomeTaxAccrued",
          },
          {
            text: "Stated Capital",
            align: "start",
            sortable: false,
            value: "statedCapital",
          },
          {
            text: "Accumulated Earnings",
            align: "start",
            sortable: false,
            value: "accumulatedEarnings",
          },

          {
            text: "Number of Employees",
            align: "start",
            sortable: false,
            value: "numberOfEmployees",
          },

          {
            text: "Tangible Assets",
            align: "start",
            sortable: false,
            value: "assets",
          },
        ],
        table2: [
          {
            text: "",
            align: "start",
            sortable: false,
            value: "index",
          },
          {
            text: "",
            align: "start",
            sortable: false,
            value: "flag",
          },
          {
            text: "Country Code",
            align: "start",
            sortable: false,
            value: "countryCode",
          },
          {
            text: "Registered Name",
            align: "start",
            sortable: false,
            value: "registeredName",
          },
          {
            text: "Trading Name",
            align: "start",
            sortable: false,
            value: "tradingName",
          },
          {
            text: "Company Registration Number",
            align: "start",
            sortable: false,
            value: "companyRegistrationNumber",
          },
          {
            text: "Issued by Country",
            align: "start",
            sortable: false,
            value: "issuedByCountry1",
          },
          {
            text: "Tax Reference Number",
            align: "start",
            sortable: false,
            value: "taxReferenceNumber",
          },
          {
            text: "Issued by Country",
            align: "start",
            sortable: false,
            value: "issuedByCountry2",
          },
          {
            text: "Incorporated Country Code",
            align: "start",
            sortable: false,
            value: "incorporatedCountryCode",
          },
          {
            text: "Address Type",
            align: "start",
            sortable: false,
            value: "addressType",
          },
          {
            text: "Address",
            align: "start",
            sortable: false,
            value: "address",
          },
          {
            text: "Main business activities",
            align: "start",
            sortable: false,
            value: "mainBusinessActivities",
          },
          {
            text: "Other business activity",
            align: "start",
            sortable: false,
            value: "otherBusinessActivity",
          },
        ],
        table3: [
          {
            text: "",
            align: "start",
            sortable: false,
            value: "index",
          },
          {
            text: "Description",
            align: "start",
            sortable: false,
            value: "description",
          },
          {
            text: "Tax Jurisdiction(s)",
            align: "start",
            sortable: false,
            value: "countryCodes",
          },
          {
            text: "Summary Ref Code(s)",
            align: "start",
            sortable: false,
            value: "summaryRefCodes",
          },
        ],
      },

      // PowerBi
      powerBiPages: [],

      disableToggleButtons: false,

      // Map
      sourceDataForTheMap: [],
      chartOptions: {
        chart: {
          // map: worldMap
        },

        title: {
          text: "Aggregated Inputs",
          // text: "Profit"
        },
        subtitle: {
          text: null,
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            alignTo: "spacingBox",
          },
        },

        colorAxis: {
          min: 1,
          type: "logarithmic",
          stops: [
            [0, "#00305752"],
            [0.33, "#ffb999"],
            [1, "#FE5000"],
          ],
          labels: {
            formatter: function () {
              if (this.value < 1000) {
                return this.value;
              } else if (this.value < 1000000) {
                return this.value / 1000 + "K";
              } else if (this.value < 1000000000) {
                return this.value / 1000000 + "M";
              } else if (this.value < 1000000000000) {
                return this.value / 1000000000 + "B";
              } else if (this.value < 1000000000000000) {
                return this.value / 1000000000000 + "T";
              }
            },
          },
          tickLength: 10,
        },

        tooltip: {
          headerFormat: "",
          pointFormat:
            "<b>{point.name}</b><br />Revenue:    {point.values.revenue:.0f}<br/>Capital:     {point.values.capital:.0f}<br/>Headcount: {point.values.headcount:.0f} people<br/>",
        },

        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: "{point.hc-a2}",
              color: "#000000",
              style: {
                textOutline: false,
              },
            },
          },
        },

        series: [
          {
            color: "#00305752",
            name: "Source data",
            mapData: worldMap,
            joinBy: ["iso-a2", "code"],
            states: {
              hover: {
                color: "#FE5000",
              },
            },

            data: [
              {
                code: "ZA",
                value: 100,
                values: {
                  revenue: 1,
                  capital: 2,
                  headcount: 3,
                },
              },
            ],
          },
        ],
      },
    };
  },
  computed: {
    isLoggedIn() {
      return store.state.isLoggedIn;
    },
    tokenInUse() {
      return store.state.tokenInUse;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    globalOverlay() {
      return store.state.globalOverlay;
    },
    screenSize() {
      return store.state.screenSize;
    },
  },
  async mounted() {
    // Start overlay loader
    store.dispatch("toggleGlobalOverlay");

    // Load console
    await this.loadAnalyticsConsole();

    // Make sure elements size properly
    let payload = {
      height: store.state.height,
      width: store.state.width,
    };
    store.state.setScreenSize(payload);

    // this.isMounted = true;
    // document.fonts.ready.then(() => {
    //   this.updateSizes();
    // });
  },
  watch: {
    screenSize: function () {
      // Update map height if the screensize changes
      let mapObject = document.getElementsByClassName("overviewMap")[0];
      mapObject.style.height = this.screenSize.height * 0.55 + "px";

      // Update font size for paragraph tag
      // let paras = document.querySelectorAll("p");
      // for (let i = 0; i < paras.length; i++) {
      //   paras[i].style.fontSize = this.screenSize.height / 1000 + "em";
      // }
    },
  },
  methods: {
    formatAsCurrency(value) {
      // Create our number formatter.
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: this.currencyCode,
      });

      return formatter.format(value);
    },
    formatNumber(value) {
      let newValue = Math.ceil(value).toString();
      let formattedValue = [];
      let indexOfSingleNumber = 1;
      for (let index = newValue.length - 1; index >= 0; index--) {
        formattedValue.push(newValue[index]);
        if (indexOfSingleNumber % 3 == 0) {
          formattedValue.push("-");
        }
        indexOfSingleNumber++;
      }
      formattedValue = formattedValue.reverse();
      formattedValue = formattedValue.join();
      formattedValue = formattedValue.replace(/,/g, "");
      formattedValue = formattedValue.replace(/-/g, " ");

      return formattedValue;
    },
    async loadAnalyticsConsole() {
      // Retrieve all data from the database
      let payload = null;
      let res = await MainServices.getSourceData(payload);

      this.sourcDataRetreived = true;
      this.sourceData = res.data;

      // console.log(this.sourceData);
      // console.log(res.data);

      // Get currency
      this.currencyCode = this.sourceData[0].currencyCode;

      // Get years
      for (let index = 0; index < this.sourceData.length; index++) {
        this.years.push(this.sourceData[index].year);
      }

      // Sort by descending order
      this.years = this.years.sort((a, b) => (a < b ? 1 : -1));

      // Generate map data
      for (let index = 0; index < this.sourceData.length; index++) {
        let mapPoints = [];
        this.sourceData[index].consolidateReports.forEach((report) => {
          mapPoints.push({
            code: report.countryCode,
            value: report.revenue.total <= 0 ? 1 : report.revenue.total,

            // Used to generate heatmaps for Multichoice data
            // value:
            //   report.summary.profitOrLossBeforeIncomeTax <= 0
            //     ? 1
            //     : report.summary.profitOrLossBeforeIncomeTax,
            values: {
              revenue: this.formatNumber(report.revenue.total),
              capital: this.formatNumber(report.summary.statedCapital),
              headcount: report.summary.numberOfEmployees,
            },
          });

          // Find max revenue
          // if (report.revenue.total > maxVal) {
          //   maxVal = report.revenue.total;
          // }
        });
        this.sourceDataForTheMap.push(mapPoints);
        // this.maxValues.push(maxVal);
      }

      // Find and set max of color axis
      // console.log(this.maxValues);
      // this.chartOptions.colorAxis.max = this.maxValues[0];
      this.chartOptions.series[0].data = this.sourceDataForTheMap[0];
      (this.chartOptions.subtitle.text = "Currency used: " + this.currencyCode),
        // Setup the powerBi Embedded frame
        await this.setupPowerBiEmbedded();
    },
    async setupPowerBiEmbedded() {
      const response = await MainServices.getEmbedToken();
      const embedData = response.data.embedData;

      // Create a config object with type of the object, Embed details and Token Type
      let models = pbi.models;
      this.reportConfiguration = {
        type: "report",
        tokenType: models.TokenType.Embed,
        accessToken: embedData.accessToken,
        embedUrl: embedData.embedUrl.embedUrl,
        settings: {
          // background: models.BackgroundType.Transparent,
          panes: {
            filters: {
              visible: false,
            },
            pageNavigation: {
              visible: false,
            },
            layoutType: models.LayoutType.Custom,
            customLayout: {
              displayOption: models.DisplayOption.ActualSize,
            },
          },
        },
      };

      this.powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );

      // Get a reference to the embedded report HTML element
      this.reportContainer = document.getElementById("reportContainer");

      // Embed the report and display it within the div container.
      this.report = await this.powerbi.embed(
        this.reportContainer,
        this.reportConfiguration
      );

      // Clear any other loaded handler events
      this.report.off("loaded");

      // Use report to create types object
      this.report.on("loaded", async () => {
        // console.log("loaded");

        // Create the categories
        this.categories = [];

        // First category - constant
        let firstCategory = new Category("Business overview");
        firstCategory.setIcon("mdi-globe-model");
        this.categories.push(firstCategory);

        this.powerBiPages = await this.report.getPages();
        for (let i = 0; i < this.powerBiPages.length; i++) {
          await this.addPbiCategory(this.powerBiPages[i]);
        }

        // console.log(this.powerBiPages);
        // console.log(this.categories);

        // Add last category - constant
        let lastCategory = new Category("Source data");
        lastCategory.setIcon("mdi-table-large");
        lastCategory.addSubCategory(new SubCategory("Table 1"));
        lastCategory.addSubCategory(new SubCategory("Table 2"));
        lastCategory.addSubCategory(new SubCategory("Table 3"));
        this.categories.push(lastCategory);

        // console.log(this.categories);

        // Stop overlay loader
        store.dispatch("toggleGlobalOverlay");
        this.reportContainer.style.display = "block";
        this.loadCategory(0);
      });
    },
    async getJSONStringFromTextBoxTitle(page) {
      let visuals = await page.getVisuals();
      let details;

      for (let index = 0; index < visuals.length; index++) {
        if (
          visuals[index].type == "textbox" &&
          visuals[index].title != undefined
        ) {
          details = {
            name: "name",
            icon: "",
          };
          details = JSON.parse(visuals[index].title);

          // console.log("visuals[index].title");
          // console.log(visuals[index].title);
        }
      }

      return details;
    },
    async addPbiCategory(page) {
      // let newCategory;

      // Page name format: Category name - X - Y
      // where X = sub category number
      // and Y = sub sub category number

      // console.log(page.displayName);
      // console.log(page.displayName.split("-"));

      let categoryName = page.displayName.split("-")[0].trim();
      let numberOfSubCategory = page.displayName.split("-")[1]
        ? page.displayName.split("-")[1].trim()
        : undefined;
      let numberOfSubSubCategory = page.displayName.split("-")[2]
        ? page.displayName.split("-")[2].trim()
        : undefined;

      let indexOfCategory = -1;
      let indexOfSubCategory = -1;

      let details = await this.getJSONStringFromTextBoxTitle(page);

      // If the details textbox (storing the JSON string), is not found, ignore the page
      if (details == undefined) {
        // TODO: Error handling message needs thought
        return;
      }

      // Category only
      if (numberOfSubCategory == undefined) {
        let category = new Category(categoryName);
        category.setReportPageId(page.name);
        category.setIcon(details.icon);
        this.categories.push(category);
      }
      // Subcategories only
      else if (numberOfSubSubCategory == undefined) {
        let subCategory = new SubCategory(details.name);
        subCategory.setReportPageId(page.name);

        // Find category, if it already exists
        for (let index = 0; index < this.categories.length; index++) {
          if (this.categories[index].name == categoryName) {
            indexOfCategory = index;
            break;
          }
        }

        // Create category and add the first subcategory
        if (indexOfCategory == -1) {
          let category = new Category(categoryName);
          category.setIcon(details.icon);

          category.addSubCategory(subCategory);
          this.categories.push(category);
        } else {
          this.categories[indexOfCategory].addSubCategory(subCategory);
        }
      }
      // SubSubCategories
      else {
        const subSubCategory = new SubSubCategory(page.name);
        // Find category, and sub category, if it already exists
        for (let index1 = 0; index1 < this.categories.length; index1++) {
          if (this.categories[index1].name == categoryName) {
            indexOfCategory = index1;
            // Find sub category that the sub sub category belongs to
            for (
              let index2 = 0;
              index2 < this.categories[indexOfCategory].subCategories.length;
              index2++
            ) {
              if (
                this.categories[index1].subCategories[index2].name ==
                details.name
              ) {
                indexOfSubCategory = index2;
                break;
              }
            }
            break;
          }
        }

        // console.log("Category found: ", indexOfCategory);
        // console.log("Subcategory found: ", indexOfSubCategory);

        // Create new category, sub category and add the first sub sub category
        if (indexOfCategory == -1) {
          let category = new Category(categoryName);
          category.setIcon(details.icon);

          let subCategory = new SubCategory(details.name);
          subCategory.addSubSubCategory(subSubCategory);

          category.addSubCategory(subCategory);
          this.categories.push(category);
        } else {
          // Create sub category and add the first sub sub category
          if (indexOfSubCategory == -1) {
            let subCategory = new SubCategory(details.name);
            subCategory.addSubSubCategory(subSubCategory);

            this.categories[indexOfCategory].addSubCategory(subCategory);
          } else {
            this.categories[indexOfCategory].subCategories[
              indexOfSubCategory
            ].addSubSubCategory(subSubCategory);
          }
        }
      }
    },
    async changeSubSubCategory(direction) {
      // direction
      //  0: left,
      //  1: right

      // Disable buttons
      this.disableToggleButtons = !this.disableToggleButtons;

      // Handle direction
      direction == 0
        ? (this.activeSubSubCategoryIndex -= 1)
        : (this.activeSubSubCategoryIndex += 1);

      // Handle wrap around
      if (
        this.activeSubSubCategoryIndex ==
        this.categories[this.activeCategoryIndex].subCategories[
          this.activeSubCategoryIndex
        ].subSubCategories.length
      ) {
        this.activeSubSubCategoryIndex = 0;
      } else if (this.activeSubSubCategoryIndex < 0) {
        this.activeSubSubCategoryIndex =
          this.categories[this.activeCategoryIndex].subCategories[
            this.activeSubCategoryIndex
          ].subSubCategories.length - 1;
      }

      // Update the PowerBi iframe
      await this.loadPowerBiPage();
    },
    async filterYear(year) {
      try {
        // console.log("Filter", year);

        this.toggleLoader();
        this.activeYearIndex = this.years.indexOf(year);

        // Business overview - update highcharts map
        if (this.activeCategoryIndex == 0) {
          // this.chartOptions.colorAxis.max = this.maxValues[
          //   this.activeYearIndex
          // ];
          this.chartOptions.series[0].data =
            this.sourceDataForTheMap[this.activeYearIndex];
          setTimeout(() => {
            this.toggleLoader();
          }, 1000);
        }

        // If powerbi category is showing, update filter
        else if (
          this.activeCategoryIndex != 0 &&
          this.activeCategoryIndex < this.categories.length - 1
        ) {
          // console.log("Filter powerbi");

          let yearValueToCompareTo = year.concat("-01-30T22:00:00.000Z");
          const yearFilter = new pbi.models.BasicFilter(
            {
              $schema: "http://powerbi.com/product/schema#basic",
              table: "Source Data",
              column: "Year ", // space at the end required
            },
            "In", // operator
            yearValueToCompareTo // values
          );

          // Apply filter
          // let filters = await this.report.getFilters();
          await this.report.removeFilters();
          await this.report.setFilters([yearFilter]);
          // filters = await this.report.getFilters();
          this.toggleLoader();
        }
      } catch (error) {
        // console.log("error");
        // console.error(error);
      }
    },
    toggleLoader() {
      // console.log("toggle loader");
      this.displaySectionLoading = !this.displaySectionLoading;
      this.disableToggleButtons = !this.disableToggleButtons;
      document.querySelector("iframe").style.border = 0;
    },
    toggleLoadingForPowerBiCategory() {
      // console.log("toggleLoadingForPowerBiCategory");
      if (this.displaySectionLoading == true) {
        this.reportContainer.style.visibility = "visible";
        this.displaySectionLoading = false;
      } else {
        this.reportContainer.style.visibility = "hidden";
        this.displaySectionLoading = true;
      }
    },
    loadCategory(typeOfCategory) {
      // typeOfCategory
      // - 0: bussiness overview
      // - 1: powerbi category (load pbi iframe)
      // - 2: source data

      typeOfCategory == 0
        ? (document.getElementById("businessOverviewCategory").style.display =
            "block")
        : (document.getElementById("businessOverviewCategory").style.display =
            "none");
      typeOfCategory == 1
        ? (document.getElementById("powerBiCategory").style.display = "block")
        : (document.getElementById("powerBiCategory").style.display = "none");
      typeOfCategory == 2
        ? (document.getElementById("sourceDataCategory").style.display =
            "block")
        : (document.getElementById("sourceDataCategory").style.display =
            "none");

      // // console.log("Type of category", typeOfCategory);
      // document.getElementById("businessOverviewCategory").style.display =
      //   "none";
      // document.getElementById("powerBiCategory").style.display = "none";
      // // document.getElementById("subSubCategoryNavigation").style.display =
      // //   "none";
      // document.getElementById("sourceDataCategory").style.display = "none";

      // if (typeOfCategory == 0) {
      //   document.getElementById("businessOverviewCategory").style.display =
      //     "block";
      // } else if (typeOfCategory == 0) {
      //   document.getElementById("businessOverviewCategory").style.display =
      //     "block";
      //   // document.getElementById("subSubCategoryNavigation").style.display =
      //   //   "block";
      // } else if (typeOfCategory == 0) {
      //   document.getElementById("businessOverviewCategory").style.display =
      //     "block";
      // }
    },
    async changeCategoryType(categoryType, subCategoryType) {
      // Only update if something new was clicked
      if (
        this.activeCategoryIndex != categoryType ||
        (this.activeCategoryIndex == categoryType &&
          this.activeSubCategoryIndex != subCategoryType)
      ) {
        this.toggleLoader();
        this.activeCategoryIndex = categoryType;
        this.activeSubCategoryIndex = subCategoryType;

        // Reset subsubcategory
        this.activeSubSubCategoryIndex = 0;

        // Bottom navigation buttons
        this.displayFullHeightForPowerBiEmbeddedSection = true;
        this.showSubSubCategoriesNavigation = false;

        setTimeout(async () => {
          this.toggleLoader();

          // Handle the next category
          if (categoryType == 0) {
            this.loadCategory(0);
          } else if (categoryType == this.categories.length - 1) {
            this.loadCategory(2);
          } else {
            // Power Bi
            // this.toggleLoader(); // counteract
            this.loadCategory(1);
            await this.loadPowerBiPage();
            // console.log("filter?")
            // await this.filterYear(this.years[0]);
          }
        }, 300);
      }
    },
    async loadPowerBiPage() {
      // console.log("Load pbi page", this.subCategoryIndex);

      // Category only
      if (
        this.categories[this.activeCategoryIndex] &&
        this.categories[this.activeCategoryIndex].subCategories.length == 0
      ) {
        //
        // console.log("category");
        this.reportConfiguration.pageName =
          this.categories[this.activeCategoryIndex].id;
      }
      // Sub categories only
      else if (
        this.categories[this.activeCategoryIndex].subCategories[
          this.activeSubCategoryIndex
        ].subSubCategories.length == 0
      ) {
        //
        // console.log("sub");
        this.reportConfiguration.pageName =
          this.categories[this.activeCategoryIndex].subCategories[
            this.activeSubCategoryIndex
          ].id;
      }
      // Sub Sub categories
      else {
        //
        this.displayFullHeightForPowerBiEmbeddedSection = false;
        this.showSubSubCategoriesNavigation = true;
        // console.log("sub sub");
        this.reportConfiguration.pageName =
          this.categories[this.activeCategoryIndex].subCategories[
            this.activeSubCategoryIndex
          ].subSubCategories[this.activeSubSubCategoryIndex].id;
      }

      // this.reportConfiguration.pageName = "ReportSection8603876cfe683cdd5bd6";

      // Embed the report
      this.report = await this.powerbi.embed(
        this.reportContainer,
        this.reportConfiguration
      );

      // Clear any other loaded handler events
      this.report.off("loaded");

      this.report.on("loaded", async () => {
        // console.log("loaded");
        await this.filterYear(this.years[this.activeYearIndex]);
        // this.toggleLoader();
      });
    },
  },
};
</script>

<style scoped>
.login-background {
  /* background: #bdbdbd; */
}
.errorr {
  vertical-align: center;
}
.heading1 {
  color: #003057;
}
.text1 {
  color: grey;
}
.styleObj {
  /* background-image: url(../../assets/Graphene_WebBackgrounds-02.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top right;
  background-size: 100% 100%; */
  /* background-color: #273c4e; */
  background-color: white;
}

.active-list {
  color: orange;
}

iframe {
  border: 0;
  border-width: 0;
}

.v-input__icon .v-input__icon--prepend {
  color: red;
  /* color: #00305752; */
  /* :color="$vuetify.theme.themes[theme].secondary" */
}

.overviewMap {
  margin: 0 auto;
}
</style>
