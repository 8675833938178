<template>
  <v-app class="styleObj">
    <!-- Header -->
    <Header />

    <v-container fill-height fluid>
      <v-row no-gutters dense>
        <v-col align="center" class="ml-4 mr-2">
          <v-hover v-slot="{ hover }">
            <v-card
              width="500"
              :color="$vuetify.theme.themes[theme].primary"
              :elevation="hover ? 16 : 4"
              @click.stop="toolSelected('cbc-uploads', 1)"
            >
              <icon
                name="infographic-xml-converter"
                :width="400"
                :height="300"
              ></icon>

              <v-card-text
                style="position: relative;"
                class="display-1 font-weight-medium mb-2 pt-0"
              >
                <p v-if="hover" style="color: #FE5000">
                  CbC upload solutions
                </p>
                <p v-if="!hover" style="color: white">
                  CbC upload solutions
                </p>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
        <v-col align="center" class="ml-2 mr-4">
          <v-hover v-slot="{ hover }">
            <v-card
              width="500"
              :color="$vuetify.theme.themes[theme].primary"
              :elevation="hover ? 16 : 4"
              @click.stop="toolSelected('cbc-analytics', 2)"
            >
              <icon
                name="infographic-analytics"
                :width="400"
                :height="300"
              ></icon>

              <v-card-text
                style="position: relative;"
                class="display-1 font-weight-medium mb-2 pt-0"
              >
                <p v-if="hover" style="color: #FE5000">
                  CbC analytics
                </p>
                <p v-if="!hover" style="color: white">
                  CbC analytics
                </p>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>

    <!-- Access denied Dialog -->
    <v-dialog id="accessDenied" v-model="accessDenied" width="400" persistent>
      <v-card>
        <v-card-title class="headline red white--text" primary-title>
          Access denied!</v-card-title
        >
        <v-card-text>
          Please contact admin should you wish to have access to this tool.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text outlined @click="closeAccessDeniedDialog">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Footer -->
    <!-- <Footer /> -->
  </v-app>
</template>

<script>
import store from "../store.js";
import Header from "../components/Header.vue";
// import Footer from "../components/Footer.vue";

export default {
  components: {
    Header
    // Footer
  },
  data() {
    return {
      drawer: false,
      group: 0
    };
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    accessDenied() {
      return store.state.accessControl;
    }
  },
  methods: {
    toolSelected(tool, navSelected) {
      this.$router.push(`/solutions/${tool}`);
      store.dispatch("setNavSelected", navSelected);
    },
    closeAccessDeniedDialog() {
      store.dispatch("toggleAccessControl");
    }
  }
};
</script>

<style lang="scss" scoped>
.styleObj {
  // background-color: #003057fb;
  // height: 100%;
}
</style>
