<template>
  <svg :height="height" :width="width" style="margin: 0 1rem">
    <use xmlns:xlink="http://www.w3.org/1999/xlink" :xlink:href="iconId" />
  </svg>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: 32
    },
    height: {
      type: Number,
      default: 32
    }
  },
  computed: {
    iconId() {
      return `#icon-${this.name}`;
    }
  }
};
</script>

<style lang="scss"></style>
