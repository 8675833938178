// Properties for category created using powerbi pages
class SubSubCategory {

    // Public field declarations
    id = null;

    constructor(id) {
        this.id = id;
    }
}

module.exports = SubSubCategory;