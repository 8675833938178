<template>
  <v-app class="background">
    <v-main>
      <v-container>
        <v-row align="start" style="z-index: 1" class="mt-16">
          <v-col :cols="4">
            <icon
              name="logo-white"
              :width="screenSize.width * 0.2085"
              :height="screenSize.width * 0.134"
            ></icon>
          </v-col>
          <v-col :cols="5" :offset="3">
            <p class=" font-weight-light white--text text-justify body-2">
              The Graphene Economics Technology platform is an online solution
              which uses rich transfer pricing, Country-by-Country and other tax
              related data sets to provide critical business insights. Our core
              offering is descriptive analytics which allows for improving and
              deepening the understanding of certain information that is
              routinely gathered but usually buried into infinite amounts of
              quantitative data and sorted into large Excel files.
              <br />
              <br />
              Our services range from Country-by-Country Report upload
              solutions, to industry and market comparative analyses. These
              analytics are critical to the transfer pricing life cycle, from
              strategy and planning to price setting, compliance, intercompany
              financial models and even dispute resolution.
            </p>
          </v-col>
        </v-row>

        <br />
        <br />

        <v-row align="start">
          <v-col :offset="7">
            <v-card :elevation="24">
              <!-- Title -->
              <v-toolbar
                dark
                :color="$vuetify.theme.themes[theme].primary"
                class="elevation-0"
              >
                <v-toolbar-title class="font-weight-medium ml-4"
                  >Login</v-toolbar-title
                >
              </v-toolbar>

              <!-- Input fields -->
              <v-form>
                <!-- Email input field -->
                <v-row>
                  <v-col cols="10" class="ml-7 mt-4 pb-0 ">
                    <v-text-field
                      v-model="username"
                      prepend-icon="person"
                      name="username"
                      label="Username"
                      type="text"
                      @keyup.enter.native="login"
                    />
                  </v-col>
                </v-row>

                <!-- Password input field -->
                <v-row>
                  <v-col cols="10" class="ml-7 mt-0 pt-0">
                    <v-text-field
                      id="password"
                      v-model="password"
                      prepend-icon="lock"
                      name="password"
                      label="Password"
                      type="password"
                      @keyup.enter.native="login"
                    />
                  </v-col>
                </v-row>
              </v-form>

              <!-- Error message -->
              <v-flex xs10 offset-xs1>
                <v-alert
                  v-if="errorMessage"
                  :value="true"
                  color="error"
                  icon="warning"
                  outlined
                  >{{ errorMessage }}</v-alert
                >
              </v-flex>

              <!-- Login Button -->
              <v-row justify="start">
                <v-col cols="10" align="start" class="ml-8">
                  <v-btn
                    dark
                    :color="$vuetify.theme.themes[theme].secondary"
                    @click="login"
                    >Login</v-btn
                  >
                </v-col>
              </v-row>
              <br />
            </v-card>
          </v-col>
        </v-row>
        <!-- {{ screenSize }} -->
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import store from "../store.js";
import MainServices from "../services/MainServices";

export default {
  data() {
    return {
      // username: "admin", // dummy
      // password: "gr@ph3n3_2021", // dummy
      // username: "me", // dummy
      // password: "bigbay", // dummy
      username: null,
      password: null,
      errorMessage: null,
      verifyButtonLoading: false,
      myImage: require("../../assets/landing.jpg"),
      cssProps: {
        backgroundImage: `url(${require("../../assets/landing.jpg")})`,
        "background-position": "left"
      }
    };
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    screenSize() {
      return store.state.screenSize;
    }
  },
  watch: {
    tab: function() {
      if (this.username) this.username = null;
      if (this.password) this.password = null;
      if (this.errorMessage) this.errorMessage = null;
    },
    screenSize: function() {
      this.setBackgroundImageSize();
    }
  },
  mounted() {
    store.dispatch("setScreenSize", store.state.screenSize);
    this.setBackgroundImageSize();
  },
  methods: {
    clear() {
      this.errorMessage = "";
    },
    async login() {
      try {
        // Start overlay loader
        store.dispatch("toggleGlobalOverlay");

        this.errorMessage = null;

        const payload = {
          username: this.username,
          password: this.password
        };

        // // Attempt to login
        let res = await MainServices.login(payload);

        const user = res.data.user;
        const authToken = res.headers.authorization.split(" ")[1];

        // Else, continue...
        // Maybe welcome the user upon a successful login

        // Remove setTimeout for production
        setTimeout(() => {
          // Update the Vuex store
          store.dispatch("login", {
            user,
            authToken
          });

          // Stop overlay loader
          store.dispatch("toggleGlobalOverlay");

          if (user.level == 0) {
            store.dispatch("setNavSelected", 0);
            this.$router.push("/solutions");
          } else if (user.level == 1) {
            store.dispatch("setNavSelected", 100);
            this.$router.push("/admin");
          }
        }, 500);
      } catch (error) {
        this.errorMessage = "Incorrect login details.";

        // Stop overlay loader
        store.dispatch("toggleGlobalOverlay");
        // console.log(error);
      }
    },
    setBackgroundImageSize() {
      // Update background image dimensions if the screensize changes
      let backgroundObject = document.getElementsByClassName("background")[0];
      // console.log("Screen size changed");
      // console.log(backgroundObject);

      let newProp =
        this.screenSize.width / 2 + "px" + " " + this.screenSize.height + "px";
      // console.log(newProp);

      backgroundObject.style.backgroundSize = newProp;
    }
  }
};
</script>

<style scoped>
.landing__background {
  position: fixed;
  z-index: 0;
}
.login-background {
  background: "#EBE6DB";
}
.logo {
  /* background-image: url(../../assets/GE_logo2.svg); */
  width: 240;
  height: auto;
}

.background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../../assets/landing.jpg");
  background-color: #003057fb;
  background-repeat: no-repeat;
  /* background-size: 850px; */
}
</style>
