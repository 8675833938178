const MainServices = (require("../services/MainServices")).default;

module.exports = {
    async download(payload) {

        // Get file
        let { type } = payload;
        let response = await MainServices.getFile(payload);

        // console.log("Download controller");
        // console.log(response.data);

        // Select the desired mimetype for the type of file being downloaded
        // Convert the file to the correct format
        let data;
        let mimetype;
        let downloadLink = document.getElementById("download"); // INCLUDE <a id="download"></a> in module
        if (type == 0) {
            data = response.data.file.excelFile.data;
            mimetype = this.getMimetype(response.data.file.excelFileExtension);
            downloadLink.download = "Spreadsheet Uploaded";
        } else if (type == 1) {
            data = response.data.file.certificate.data;
            mimetype = "application/pdf";
            downloadLink.download = "XML Converter Certificate";
        }
        else if (type == 2) {
            data = response.data.file.xmlFile.data;
            mimetype = "text/xml";
            downloadLink.download = "XML File";
        }

        let arrayBuffer2 = new Uint8Array(data).buffer;
        let blob = new Blob([arrayBuffer2], {
            type: mimetype
        });

        let dataURL = window.URL.createObjectURL(blob);
        downloadLink.href = dataURL;
        downloadLink.target = "_blank";
    },
    getMimetype(extension) {
        let mimetype;
        // Get mimetype
        switch (extension) {
            case ".xls":
                // Microsoft Excel
                mimetype = "application/vnd.ms-excel";
                break;
            case ".xlam":
                // Microsoft Excel - Add-In File
                mimetype = "application/vnd.ms-excel.addin.macroenabled.12";
                break;
            case ".xlsb":
                // Microsoft Excel - Binary Workbook
                mimetype = "application/vnd.ms-excel.sheet.binary.macroenabled.12";
                break;
            case ".xltm":
                // Microsoft Excel - Macro-Enabled Template File
                mimetype = "application/vnd.ms-excel.template.macroenabled.12";
                break;
            case ".xlsm":
                // Microsoft Excel - Macro-Enabled Workbook
                mimetype = "application/vnd.ms-excel.sheet.macroenabled.12";
                break;
            case ".xlsx":
                // Microsoft Office - OOXML - Spreadsheet
                mimetype =
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                break;
            case ".xltx":
                // Microsoft Office - OOXML - Spreadsheet Teplate
                mimetype =
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.template";
                break;
            case ".pdf":
                // Adobe Portable Document Format
                mimetype = "application/pdf";
                break;
        }
        return mimetype;
    },
    getFileExtension(mimetype) {
        let fileExtension;
        // Get mimetype
        switch (mimetype) {
            case "application/vnd.ms-excel":
                // Microsoft Excel
                fileExtension = ".xls";
                break;
            case "application/vnd.ms-excel.addin.macroenabled.12":
                // Microsoft Excel - Add-In File
                fileExtension = ".xlam";
                break;
            case "application/vnd.ms-excel.sheet.binary.macroenabled.12":
                // Microsoft Excel - Binary Workbook
                fileExtension = ".xlsb";
                break;
            case "application/vnd.ms-excel.template.macroenabled.12":
                // Microsoft Excel - Macro-Enabled Template File
                fileExtension = ".xltm";
                break;
            case "application/vnd.ms-excel.sheet.macroenabled.12":
                // Microsoft Excel - Macro-Enabled Workbook 
                fileExtension = ".xlsm";
                break;
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                // Microsoft Office - OOXML - Spreadsheet
                fileExtension =
                    ".xlsx";
                break;
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
                // Microsoft Office - OOXML - Spreadsheet Teplate
                fileExtension =
                    ".xltx";
                break;
            case "application/pdf":
                // Adobe Portable Document Format
                fileExtension = ".pdf";
                break;
        }
        return fileExtension;
    }
}