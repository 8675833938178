import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from 'vuex-persistedstate';
import * as Cookies from 'js-cookie';

Vue.use(Vuex);

// The Vuex store will act as the database since we are not using one for this simple interface
export default new Vuex.Store({
  state: {
    authToken: null,
    user: {
      id: null,
    },
    count: 0,
    navSelected: 0,
    toolSelected: "",
    accessControl: false,
    globalOverlay: false,
    mneName: null,
    screenSize: {
      height: 0,
      width: 0
    },

    // For client user: stores embed access token
    // For admin user: stores report id getting embedded
    powerBiReportEmbedded: null
  },
  mutations: {
    setToken(state, payload) {
      state.tokenInUse = payload;
    },
    clearToken(state) {
      state.tokenInUse = null
    },
    login(state, payload) {
      state.user = payload.user;
      state.authToken = payload.authToken;
    },
    logout(state) {
      state.authToken = null;
      state.user = null;
      state.navSelected = 0;
      state.toolSelected = "";
      state.accessControl = false;
      state.globalOverlay = false;
      state.mneName = null;

      // Counteract the screensize manipulation because of the admin user
      state.screenSize = {
        height: state.screenSize.height / 0.75,
        width: state.screenSize.width / 0.75
      };
      state.powerBiReportEmbedded = null;
    },
    increment(state) {
      state.count++;
    },
    setNavSelected(state, payload) {
      state.navSelected = payload;
      if (payload == 0) {
        state.toolSelected = "Solutions Hub"
      }
      else if (payload == 1) {
        state.toolSelected = "CbC upload solutions"
      }
      else if (payload == 2) {
        state.toolSelected = "CbC analytics"
      }
      else if (payload == 100) {
        state.toolSelected = "Admin Control Panel"
      }
    },
    toggleAccessControl(state) {
      state.accessControl = !state.accessControl;
    },
    toggleGlobalOverlay(state) {
      state.globalOverlay = !state.globalOverlay;
    },
    setMNEName(state, payload) {
      state.mneName = payload;
    },
    setScreenSize(state, payload) {
      state.screenSize = payload;
    },
    setPowerBiReportEmbedded(state, payload) {
      state.powerBiReportEmbedded = payload;
    },
  },
  actions: {
    setToken(context, payload) {
      context.commit("setToken", payload);
    },
    clearToken(context) {
      context.commit("clearToken");
    },
    login(context, payload) {
      context.commit("login", payload);
    },
    logout(context) {
      context.commit("logout");
    },
    increment(context) {
      context.commit("increment");
    },
    setNavSelected(context, payload) {
      context.commit("setNavSelected", payload);
    },
    toggleAccessControl(context) {
      context.commit("toggleAccessControl");
    },
    toggleGlobalOverlay(context) {
      context.commit("toggleGlobalOverlay");
    },
    setMNEName(context, payload) {
      context.commit("setMNEName", payload);
    },
    setScreenSize(context, payload) {
      context.commit("setScreenSize", payload);
    },
    setPowerBiReportEmbedded(context, payload) {
      context.commit("setPowerBiReportEmbedded", payload);
    },
  },
  plugins: [
    createPersistedState({
      getState: (key) => Cookies.getJSON(key),
      setState: (key, state) => Cookies.set(key, state, { expires: 3, secure: true })
    })
  ]
});
