<template>
  <!-- Toolbar -->
  <!-- <v-container tag="div" fluid class="pa-0 ma-0"> -->
  <div>
    <v-toolbar dark color="#003057fb " :height="72">
      <icon name="logo-white" :width="100" :height="100"></icon>
      <v-divider vertical dark class="ml-4 mr-4"></v-divider>

      <v-row no-gutters align="center">
        <v-col :cols="4" align="end">
          <v-card-title>
            {{ toolSelected }}
          </v-card-title>
          <!-- <div class="text-6 text-left font-weight-medium">
            {{ toolSelected }}
          </div> -->
        </v-col>
        <v-spacer></v-spacer>
        <v-divider vertical dark class="mr-8"></v-divider>
        <v-col :cols="1" align="center">
          <div class="text-center ">
            {{ loggedInUser.name }}
          </div>
        </v-col>
        <v-divider vertical dark class="ml-12 mr-1"></v-divider>
        <v-col :cols="1" align="center">
          <v-btn class="ml-2" icon @click.stop="drawer = !drawer">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>

    <!-- Navigation Drawer -->
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      right
      dark
      :color="$vuetify.theme.themes[theme].primary"
    >
      <!-- User profile -->
      <v-list-item two-line class=" text-left align-self-start">
        <v-list-item-avatar>
          <v-icon>mdi-account</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ loggedInUser.name }}</v-list-item-title>
          <v-list-item-subtitle v-if="isLoggedIn"
            >Logged In</v-list-item-subtitle
          >
          <v-list-item-subtitle v-else>Logged Out</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <!-- Navigation options -->
      <v-list nav>
        <v-list-item-group
          :value="navSelected"
          class="text-left align-self-start"
          v-on:change="$emit('change', 0)"
        >
          <div v-if="loggedInUser.level == 0">
            <v-list-item @click.stop="navigateTo('solutions', 0)">
              <v-list-item-title>Solutions Hub</v-list-item-title>
            </v-list-item>

            <v-list-item @click.stop="navigateTo('solutions/cbc-uploads', 1)">
              <v-list-item-title>CbC upload solutions</v-list-item-title>
            </v-list-item>

            <v-list-item @click.stop="navigateTo('solutions/cbc-analytics', 2)">
              <v-list-item-title>CbC analytics</v-list-item-title>
            </v-list-item>
          </div>

          <v-list-item @click.stop="tryLogout()">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!-- Logout dialog -->
    <v-dialog id="logoutDialog" v-model="logoutDialog" width="300" persistent>
      <v-card>
        <v-toolbar
          dark
          :color="$vuetify.theme.themes[theme].primary"
          class="elevation-0"
        >
          <v-icon right left dark>mdi-export</v-icon>
          <v-toolbar-title class="font-weight-medium">Logout</v-toolbar-title>
        </v-toolbar>
        <br />
        <v-card-text>Are you sure you want to log out?</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text outlined @click="closeDialog()">No</v-btn>
          <v-btn text outlined @click="logout()">Yes</v-btn>
        </v-card-actions>
        <v-card-text></v-card-text>
      </v-card>
    </v-dialog>
    <!-- </v-container> -->
  </div>
</template>

<script>
import store from "../store.js";

export default {
  data() {
    return {
      drawer: false,
      logoutDialog: false,
      previousNavSelected: 0
    };
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    navSelected() {
      return store.state.navSelected;
    },
    isLoggedIn() {
      return store.state.authToken ? true : false;
    },
    loggedInUser() {
      return store.state.user;
    },
    toolSelected() {
      return store.state.toolSelected;
    }
  },
  methods: {
    navigateTo(path, navSelected) {
      this.$router.push(`/${path}`);
      store.dispatch("setNavSelected", navSelected);
    },
    tryLogout() {
      this.drawer = false;
      this.logoutDialog = true;
      this.previousNavSelected = this.navSelected;
    },
    logout() {
      store.dispatch("logout");
      this.logoutDialog = false;
      this.$router.push("/");
    },
    closeDialog() {
      // console.log("Closing dialog");
      // console.log("Prev nav, ", this.previousNavSelected);
      this.logoutDialog = false;
      store.dispatch("setNavSelected", this.previousNavSelected);
    }
  }
};
</script>

<style lang="scss" scoped></style>
