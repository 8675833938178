// Properties for category created using powerbi pages
class SubCategory {

    // Public field declarations
    name = null;
    id = null;
    subSubCategories = [];

    constructor(name) {
        this.name = name;
    }

    // If the category has no sub-categories, give it an id (so the report page can be pulled through)
    setReportPageId(id) {
        this.id = id;
        return;
    }

    addSubSubCategory(subSubCategory) {
        this.subSubCategories.push(subSubCategory);
        return;
    }

}

module.exports = SubCategory;