<template>
  <v-app id="App" class=".v-application">
    <!-- Component matched by the route will render here -->
    <router-view></router-view>

    <!-- Loading overlay -->
    <v-overlay :value="globalOverlay">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-app>
</template>

<script>
import store from "../src/store.js";

export default {
  data() {
    return {};
  },
  computed: {
    globalOverlay() {
      return store.state.globalOverlay;
    },
    isUserLoggedIn() {
      return store.state.user ? true : false;
    }
  },
  mounted() {
    // Reset global overlay if on
    if (store.state.globalOverlay == true) {
      store.dispatch("toggleGlobalOverlay");
    }

    this.calculateScreenSize();

    // Set up an event listener to recalculate the screensize if it changes
    window.addEventListener("resize", this.calculateScreenSize);
  },
  watch: {
    isUserLoggedIn: function() {
      this.calculateScreenSize();
    }
  },
  methods: {
    calculateScreenSize() {
      // Get the screensize [height/width]
      let width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      let height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      // Check if an Admin user is logged in
      // Mimic "screensize" for embedded tools pages on the admin side
      if (store.state.user && store.state.user.level == 1) {
        height *= 0.75;
        width *= 0.75;
      }

      let payload = {
        height,
        width
      };

      store.dispatch("setScreenSize", payload);

      // // Update font size based on screensize:
      // let newFontSize = width / 1199;
      // document.getElementById("app").style.fontSize = newFontSize + "em";
    }
  }
};
</script>

<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #022847fb;
  height: 100%;
  font-size: 1em;
}
</style>
