// Properties for category created using powerbi pages
class Category {

    // Public field declarations
    icon = null;
    name = null;
    id = null;
    subCategories = [];

    constructor(name) {
        this.name = name;
    }

    // If the category has no sub-categories, give it an id (so the report page can be pulled through)
    setReportPageId(id) {
        this.id = id;
        return;
    }

    // Set the icon
    setIcon(icon) {
        this.icon = icon;
        return;
    }

    addSubCategory(subCategory) {
        this.subCategories.push(subCategory);
        return;
    }
}

module.exports = Category;