import Vue from "vue";
import VueRouter from "vue-router";

// Views
import Admin from "./views/Admin.vue";
import Landing from "./views/Landing.vue";
import Tools from "./views/Tools.vue";
import ToolXML from "./views/Tools/ToolXML.vue";
import ToolAnalytics from "./views/Tools/ToolAnalytics.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "landing",
    component: Landing,
  },
  {
    path: "/solutions",
    name: "Tools",
    component: Tools,
  },
  {
    path: "/solutions/cbc-uploads",
    name: "ToolXML",
    component: ToolXML,
  },
  {
    path: "/solutions/cbc-analytics",
    name: "ToolAnalytics",
    component: ToolAnalytics,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
