<template>
  <div>
    <v-tabs
      v-model="tab"
      fixed-tabs
      dark
      :background-color="$vuetify.theme.themes[theme].primary"
      :slider-color="$vuetify.theme.themes[theme].secondary"
    >
      <v-tab class="text-capitalize">Details</v-tab>
      <v-tab class="text-capitalize">Table 1</v-tab>
      <v-tab class="text-capitalize">Table 2</v-tab>
      <v-tab class="text-capitalize">Table 3</v-tab>
    </v-tabs>

    <!-- Details of reporting entity -->
    <v-card
      flat
      :color="$vuetify.theme.themes[theme].primary"
      :height="screenSize.height * 0.675"
    >
      <br />
      <v-card v-if="tab == 0" :height="screenSize.height * 0.6" class="mx-4">
        <br />
        <v-row justify="start">
          <v-col cols="10" align="start" offset="1">
            <strong>MNE Group Name:</strong>
            {{ extractedData.nameMNEGroup }}
            <br />
            <strong>Reporting Entity:</strong>
            {{ extractedData.reportingEntity }}
            <br />
            <strong>Reporting Role:</strong>
            {{ extractedData.reportingRole }}
            <br />
            <strong>Reporting Period start:</strong>
            {{ extractedData.startDate }}
            <br />
            <strong>Reporting Period end:</strong>
            {{ extractedData.endDate }}
            <br />
            <strong>Currency:</strong>
            {{ extractedData.currencyCode }}
          </v-col>
        </v-row>
      </v-card>

      <!-- Table 1 data table -->
      <v-card
        flat
        v-if="tab == 1"
        :color="$vuetify.theme.themes[theme].primary"
        class="mx-4"
        :height="screenSize.height * 0.65"
      >
        <v-row justify="start">
          <v-col cols="12" align="start">
            <v-data-table
              :headers="headers.table1"
              :items="extractedData.listOfReports"
              :items-per-page="25"
              :height="screenSize.height * 0.5"
              dense
              calculated-width
              flat
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, index) in items" :key="item.name">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <CountryFlag :country="item.countryCode" size="normal" />
                    </td>

                    <td>{{ item.countryCode }}</td>

                    <td>
                      {{
                        extractedData.listOfEntities[index].constituentEntity
                          .registeredName
                      }}
                    </td>
                    <td>
                      {{ formatAsCurrency(item.revenue.unrelated) }}
                    </td>
                    <td>
                      {{ formatAsCurrency(item.revenue.related) }}
                    </td>
                    <td>
                      {{ formatAsCurrency(item.revenue.total) }}
                    </td>
                    <td>
                      {{
                        formatAsCurrency(
                          item.summary.profitOrLossBeforeIncomeTax
                        )
                      }}
                    </td>
                    <td>
                      {{ formatAsCurrency(item.summary.incomeTaxPaid) }}
                    </td>
                    <td>
                      {{ formatAsCurrency(item.summary.incomeTaxAccrued) }}
                    </td>
                    <td>
                      {{ formatAsCurrency(item.summary.statedCapital) }}
                    </td>
                    <td>
                      {{ formatAsCurrency(item.summary.accumulatedEarnings) }}
                    </td>
                    <td>{{ item.summary.numberOfEmployees }}</td>
                    <td>
                      {{ formatAsCurrency(item.summary.assets) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>

      <!-- Table 2 data table -->
      <v-card
        flat
        v-if="tab == 2"
        :color="$vuetify.theme.themes[theme].primary"
        :height="screenSize.height * 0.65"
        class="mx-4"
      >
        <v-row justify="start">
          <v-col cols="12" align="start">
            <v-data-table
              :headers="headers.table2"
              :items="extractedData.listOfEntities"
              :items-per-page="25"
              :height="screenSize.height * 0.5"
              dense
              calculated-width
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, index) in items" :key="item.name">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <CountryFlag :country="item.countryCode" size="normal" />
                    </td>
                    <td>
                      {{ item.countryCode }}
                    </td>
                    <td>
                      {{ item.constituentEntity.registeredName }}
                    </td>
                    <td>
                      {{ item.constituentEntity.tradingName }}
                    </td>
                    <td>
                      {{ item.constituentEntity.companyRegistrationNumber }}
                    </td>
                    <td>
                      {{ item.constituentEntity.issuedByCountry1 }}
                    </td>
                    <td>
                      {{ item.constituentEntity.taxReferenceNumber }}
                    </td>
                    <td>
                      {{ item.constituentEntity.issuedByCountry2 }}
                    </td>
                    <td>
                      {{ item.constituentEntity.incorporatedCountryCode }}
                    </td>
                    <td>
                      {{ item.address.addressType }}
                    </td>
                    <td>
                      {{ item.address.address }}
                    </td>
                    <td>
                      {{ item.businessActivities.mainBusinessActivities }}
                    </td>
                    <td>
                      {{ item.businessActivities.otherBusinessActivity }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>

      <!-- Table 3 data table -->
      <v-card
        flat
        v-if="tab == 3"
        :color="$vuetify.theme.themes[theme].primary"
        :height="screenSize.height * 0.65"
        class="mx-4"
      >
        <v-row justify="start">
          <v-col cols="12" align="start" offset="0">
            <v-data-table
              :headers="headers.table3"
              :items="extractedData.listOfAdditionalInfos"
              :items-per-page="25"
              :height="screenSize.height * 0.5"
              dense
              calculated-width
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, index) in items" :key="item.name">
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{ item.otherInfo }}
                    </td>
                    <td>
                      {{ item.countryCodes }}
                    </td>
                    <td>
                      {{ item.summaryRefCodes }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import store from "../store.js";

export default {
  // Properties passed into the component
  props: {
    extractedData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tab: 0,
      //   extractedData: {
      //     nameMNEGroup: "",
      //     reportingEntity: "",
      //     reportingRole: "",
      //     startDate: "",
      //     endDate: "",
      //     currencyCode: "",
      //     listOfReports: [],
      //     listOfEntities: [],
      //     listOfAdditionalInfos: []
      //   },
      headers: {
        table1: [
          {
            text: "",
            align: "start",
            sortable: false,
            value: "index"
          },
          {
            text: "",
            align: "start",
            sortable: false,
            value: "flag"
          },
          {
            text: "Country Code",
            align: "start",
            sortable: false,
            value: "countryCode"
          },
          {
            text: "Registered Name",
            align: "start",
            sortable: false,
            value: "registeredName"
          },
          {
            text: "Revenue - Unrelated",
            align: "start",
            sortable: false,
            value: "revenueUnrelated"
          },
          {
            text: "Revenue - Related",
            align: "start",
            sortable: false,
            value: "revenueRelated"
          },
          {
            text: "Revenue - Total",
            align: "start",
            sortable: false,
            value: "revenueTotal"
          },
          {
            text: "Profit/Loss before Income tax",
            align: "start",
            sortable: false,
            value: "profitOrLossBeforeIncomeTax"
          },
          {
            text: "Income Tax Paid",
            align: "start",
            sortable: false,
            value: "incomeTaxPaid"
          },
          {
            text: "Income Tax Accrued",
            align: "start",
            sortable: false,
            value: "incomeTaxAccrued"
          },
          {
            text: "Stated Capital",
            align: "start",
            sortable: false,
            value: "statedCapital"
          },
          {
            text: "Accumulated Earnings",
            align: "start",
            sortable: false,
            value: "accumulatedEarnings"
          },

          {
            text: "Number of Employees",
            align: "start",
            sortable: false,
            value: "numberOfEmployees"
          },

          {
            text: "Tangible Assets",
            align: "start",
            sortable: false,
            value: "assets"
          }
        ],
        table2: [
          {
            text: "",
            align: "start",
            sortable: false,
            value: "index"
          },
          {
            text: "",
            align: "start",
            sortable: false,
            value: "flag"
          },
          {
            text: "Country Code",
            align: "start",
            sortable: false,
            value: "countryCode"
          },
          {
            text: "Registered Name",
            align: "start",
            sortable: false,
            value: "registeredName"
          },
          {
            text: "Trading Name",
            align: "start",
            sortable: false,
            value: "tradingName"
          },
          {
            text: "Company Registration Number",
            align: "start",
            sortable: false,
            value: "companyRegistrationNumber"
          },
          {
            text: "Issued by Country",
            align: "start",
            sortable: false,
            value: "issuedByCountry1"
          },
          {
            text: "Tax Reference Number",
            align: "start",
            sortable: false,
            value: "taxReferenceNumber"
          },
          {
            text: "Issued by Country",
            align: "start",
            sortable: false,
            value: "issuedByCountry2"
          },
          {
            text: "Incorporated Country Code",
            align: "start",
            sortable: false,
            value: "incorporatedCountryCode"
          },
          {
            text: "Address Type",
            align: "start",
            sortable: false,
            value: "addressType"
          },
          {
            text: "Address",
            align: "start",
            sortable: false,
            value: "address"
          },
          {
            text: "Main business activities",
            align: "start",
            sortable: false,
            value: "mainBusinessActivities"
          },
          {
            text: "Other business activity",
            align: "start",
            sortable: false,
            value: "otherBusinessActivity"
          }
        ],
        table3: [
          {
            text: "",
            align: "start",
            sortable: false,
            value: "index"
          },
          {
            text: "Description",
            align: "start",
            sortable: false,
            value: "description"
          },
          {
            text: "Tax Jurisdiction(s)",
            align: "start",
            sortable: false,
            value: "countryCodes"
          },
          {
            text: "Summary Ref Code(s)",
            align: "start",
            sortable: false,
            value: "summaryRefCodes"
          }
        ]
      }
    };
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    screenSize() {
      return store.state.screenSize;
    }
  },
  methods: {
    formatAsCurrency(value) {
      // Create our number formatter.
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: this.extractedData.currencyCode
      });

      return formatter.format(value);
    }
  }
};
</script>

<style lang="scss" scoped></style>
