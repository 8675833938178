import Api from "./Api";
import store from "../store.js";

export default {

  login(payload) {
    return Api().post("auth/login", (payload), {
      headers: {
        "Content-Type": "application/json"
      }
    });
  },

  checkAccessControlForTool(payload) {
    return Api().post("/checkAccessControlForTool", (payload), {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.state.authToken
      }
    });
  },

  extractData(payload) {
    return Api().post("/extractData", (payload), {
      params: {
        mneName: store.state.mneName
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.state.authToken
      }
    });
  },

  runXMLConversion(payload) {
    return Api().post("/runXMLConversion", (payload), {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.state.authToken
      }
    });
  },

  getFile(payload) {
    return Api().post("/getFile", (payload), {
      params: {
        mneName: store.state.mneName
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.state.authToken
      }
    });
  },

  // Power Bi embed token
  getEmbedToken(payload) {
    return Api().post("/getEmbedToken", (payload), {
      params: {
        mneName: store.state.mneName
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.state.authToken
      }
    });
  },

  getSourceData(payload) {
    return Api().post("/getSourceData", (payload), {
      params: {
        mneName: store.state.mneName
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.state.authToken
      }
    });
  },


  // ADMIN SIDE

  // MNE Client Profiles
  getAllMNEClientProfiles() {
    return Api().get("/getAllMNEClientProfiles", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.state.authToken
      }
    });
  },
  addMNEClientProfile(payload) {
    return Api().post("/addMNEClientProfile", (payload), {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.state.authToken
      }
    });
  },

  // Users
  getAllUsers(payload) {
    return Api().post("/getAllUsers", (payload), {
      params: {
        mneName: store.state.mneName
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.state.authToken
      }
    });
  },

  createUser(payload) {
    return Api().post("/createUser", (payload), {
      params: {
        mneName: store.state.mneName
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.state.authToken
      }
    });
  },

  // Source data
  getAllSourceData(payload) {
    return Api().post("/getAllSourceData", (payload), {
      params: {
        mneName: store.state.mneName
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.state.authToken
      }
    });
  },
  addSourceData(payload) {
    return Api().post("/addSourceData", (payload), {
      params: {
        mneName: store.state.mneName
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.state.authToken
      }
    });
  },

  // Analytics consoles
  getAllAnalyticsConsoles(payload) {
    return Api().post("/getAllAnalyticsConsoles", (payload), {
      params: {
        mneName: store.state.mneName
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.state.authToken
      }
    });
  },
  createAnalyticsConsole(payload) {
    return Api().post("/createAnalyticsConsole", (payload), {
      params: {
        mneName: store.state.mneName
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.state.authToken
      }
    });
  },
  getListOfExistingPowerBiReports() {
    return Api().get("/getListOfExistingPowerBiReports", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.state.authToken
      }
    });
  },
};
