import axios from "axios";
import router from "../router";

export default () => {
  const api = axios.create({
    // Dev
    // baseURL: "http://localhost:8085/", // Local server

    // Prod
    baseURL: "https://graphenetechplatform.azurewebsites.net/"

    // baseURL: process.env.VUE_APP_ROOT_API
  });


  // Auto redirect to landing page if error code 401 found
  api.interceptors.response.use(null, error => {
    let path = '/error';
    switch (error.response.status) {
      case 401: path = '/'; break;
      case 404: path = '/404'; break;
    }
    router.push(path);
    return Promise.reject(error);
  });

  return api;
};
