export default {
  themes: {
    light: {
      primary: '#003057', // custom blue
      secondary: '#FE5000', // custom orange
      accent: '#D1CCBD', // custom beige - dark
      error: '#FF5252', // standard red
      info: '#EBE6DB', // custom beige - light
      success: '#4CAF50', // standard green
      warning: '#FFC107', // standard
      options: {
        customProperties: true,
      },
    },
    dark: {
      primary: '#003057', // custom blue
      secondary: '#FE5000', // custom orange
      accent: '#D1CCBD', // custom beige - dark
      error: '#FF5252', // standard red
      info: '#EBE6DB', // custom beige - light
      success: '#4CAF50', // standard green
      warning: '#FFC107', // standard
      options: {
        customProperties: true,
      },
    },

    // light: {
    //   main: {
    //     orange: "#FE5000",
    //     blue: "#003057",
    //     white: "#FFFFFF",
    //   },
    //   beige: {
    //     light: "#EBE6DB",
    //     dark: "#D1CCBD"
    //   },
    //   orange: {
    //     primary: "#ff4d00",
    //     secondary: "#ef8200",
    //     tertiary: "#ffa200",
    //   },
    //   blue: {
    //     primary: "#003e74",
    //     secondary: "#639ec8",
    //     tertiary: "#8dc9e8",
    //   },
    //   grey: {
    //     primary: "#bdbdbd",
    //     secondary: "#8c8d8e",
    //     tertiary: "#616265",
    //   },
    //   options: {
    //     customProperties: true,
    //   },
    // },

    // dark: {
    //   main: {
    //     orange: "#ff5a00",
    //     blue: "#00325b",
    //     white: "#ffffff",
    //   },
    //   beige: {
    //     light: "#EBE6DB",
    //     dark: "#D1CCBD"
    //   },
    //   orange: {
    //     primary: "#ff4d00",
    //     secondary: "#ef8200",
    //     tertiary: "#ffa200",
    //   },
    //   blue: {
    //     primary: "#003e74",
    //     secondary: "#639ec8",
    //     tertiary: "#8dc9e8",
    //   },
    //   grey: {
    //     primary: "#bdbdbd",
    //     secondary: "#8c8d8e",
    //     tertiary: "#616265",
    //   },
    //   options: {
    //     customProperties: true,
    //   },
    // },
  },
};

/*
  Using the theme colors

  Dynamically bind to this global property:
    $vuetify.theme.orange.primary
*/
